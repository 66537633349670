import React, { useState, useEffect } from "react";
import { DobYear, DobMonth, DobDay } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";

const DateOfBirth = ({
  errors,
  register,
  setDobday,
  setDobmonth,
  setDobyear,
  notDobValid,
  prevFormData
}) => {

  return (
    <div className="date-birth row" id="date-birth">

      <div className="col-lg-4">
        <SelectBox
          className="form-select"
          OptionValue={DobDay}
          defaultValue={prevFormData?.DobDay}
          name="DobDay"
          onChange={(e) => setDobday(e.target.value)}          
          id="DobDay"
          myRef={register({
            required: "Please select date",
          })}
          validationMsg={errors.DobDay && errors.DobDay.message}
        />
        <i className="tick fa" style={{ display: "none" }}></i>
      </div>
      <div className="col-lg-4">
        <SelectBox
          className="form-select"
          OptionValue={DobMonth}
          defaultValue={prevFormData?.DobMonth}
          onChange={(e) => setDobmonth(e.target.value)}
          name="DobMonth"
          id="DobMonth"
          myRef={register({
            required: "Please select month",
          })}
          validationMsg={errors.DobMonth && errors.DobMonth.message}
        />
        <i className="tick fa" style={{ display: "none" }}></i>
      </div>

      <div className="col-lg-4">
        <SelectBox
          className="form-select"
          OptionValue={DobYear}
          defaultValue={prevFormData?.DobYear}
          name="DobYear"
          id="DobYear"
          onChange={(e) => setDobyear(e.target.value)}
          myRef={register({
            required: "Please select year",
          })}
          validationMsg={errors.DobYear && errors.DobYear.message}
        />
        <i className="tick fa" style={{ display: "none" }}></i>
      </div>
      <span id="dob_final_err" className="error_msg"></span>

      {notDobValid && (
        <span id="dob_final_err" className="error_msg">
          Invalid date of birth provided.
        </span>
      )}

    </div>
  );
};

export default DateOfBirth;
