import React, { useState, useEffect, useCallback, useRef } from "react";
import { usePostCodeAddrsChkboard } from "../../../../Hooks/usePostCodeAddrsChkboard";
import AddressTextField from "../../../UI/AddressTextField";
import { useFormContext } from "react-hook-form";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
import btnLoader from "../../../../assets/UFC_DC_V1/img/buttonLoader.gif";
import Button from "../../../UI/Button";
import AddressSelectBoxEdit from "./AddressSelectBoxEdit";

const PostCode = ({ formData, setPCodeValid }) => {
  const {
    register,
    unregister,
    trigger,
    errors,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
  } = useFormContext();

  const { getPostcodeAddress, getSplitPostcodeAddress, loading } =
    usePostCodeAddrsChkboard();
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [defaultAddress1, setDefaultAddress1] = useState({
    address1: formData?.address1,
    txtHouseNumber: formData?.txtHouseNumber,
    txtAddress2: formData?.txtAddress2,
    txtCounty: formData?.txtCounty,
    txtTown: formData?.txtTown,
    txtCountry: formData?.txtCountry,
    txtDistrict: formData?.txtDistrict,
  });

  const [addressId, setAddressId] = useState(formData?.address1);
  const scrollToBottom = useRef(null);

  useEffect(() => {
    unregister("address1");
  }, []);

  const clearFormData = () => {
    setDefaultAddress1(null);
    setSelectedAddress("");
    setAddressList([]);
    setPCodeValid(false);
  };

  const uuid = localStorage.getItem("uuid") ?? "";

  const searchAddress = useCallback(async () => {
    const isValid = await trigger("txtPostCode");

    if (isValid) {
      clearFormData();

      const postCode = watch("txtPostCode");
      await getPostcodeAddress(postCode, uuid)
        .then((res) => {
          if (res.data.status === "0") {
            return setError("txtPostCode", {
              type: "manual",
              message: "Please Enter Valid Postcode",
            });
          }
          if (res.data.length > 0) {
            clearErrors("txtPostCode");
          }

          setAddressList(res?.data ?? []);
        })
        .catch(function () {
          setError("txtPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });

          setAddressList([]);
        });
    }
  }, [
    getPostcodeAddress,
    trigger,
    watch,
    clearFormData,
    uuid,
    setError,
    clearErrors,
    setAddressList,
  ]);

  const fetchAddressDetails = useCallback(
    async (e) => {
      const isValid = await trigger("address1");

      if (isValid) {
        const address = watch("address1");
        setPCodeValid(true);

        const response = await getSplitPostcodeAddress(address);
        const data = response?.data;

        setSelectedAddress(data);

        setValue("txtHouseNumber", data?.line_1);
        setValue("txtAddress2", data?.line_2);
        setValue("txtAddress3", data?.line_3);
        setValue("txtAddress4", data?.line_4);
        setValue("txtTown", data?.town);
        setValue("txtCounty", data?.county);
        setValue("txtDistrict", data?.district);
        setValue("txtCountry", data?.country);

        const varbe = getValues();
        console.log("haehheh", varbe);
      }
    },
    [getSplitPostcodeAddress, setValue, trigger, watch, setSelectedAddress]
  );

  useEffect(() => {
    setTimeout(() => {
      if (!!selectedAddress && !!scrollToBottom) {
        scrollToBottom.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }, 200);
  }, [selectedAddress]);
  return (
    <>
      <div>
        <div className="row mb-3 ps-0 pe-0">
          <p>
            <strong>Edit your Current Address</strong>
          </p>
          <div className="col-12 col-lg-6">
            <AddressTextField
              type="text"
              placeholder="Postcode"
              name="txtPostCode"
              id="txtPostCode"
              className="form-control"
              dataId="txtPostCode"
              autoComplete="off"
              validation={register({
                required: "Please Enter Postcode",
                pattern: {
                  value:
                    /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                  message: "Please Enter a Valid Postcode",
                },
                minLength: {
                  value: 5,
                  message: "Please Enter a Valid Postcode",
                },
              })}
              validationMsg={errors["txtPostCode"]?.message}
              value={formData?.txtPostCode}
              onChange={clearFormData}
              onBlur={searchAddress}
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            {!loading && (
              <Button
                type="button"
                className="btn-lookup"
                value="Look Up Address"
                buttonText="Look Up Address"
                id="lookUp"
                onClick={searchAddress}
              />
            )}

            {!!loading && (
              <Button
                type="button"
                className="btn-lookup"
                value="Look Up Address"
                buttonText={<img src={btnLoader} alt="loading..." />}
                id="lookUp"
                disabled={!!loading}
              />
            )}
          </div>
        </div>
        <div
          className={`${
            !!addressList.length ||
            formData?.address1 ||
            formData?.txtHouseNumber
              ? ""
              : "d-none"
          }`}
        >
          <div
            className={`col-lg-12 mt-3 ${
              !!addressList.length ? "d-block" : "d-none"
            }`}
          >
            <AddressSelectBoxEdit
              className="form-select mb-lg-3"
              OptionValue={addressList.map((address) => ({
                address: address.address,
                id: address.id,
              }))}
              name="address1"
              id="address1"
              onChange={(e) => {
                // trigger("address1");
                setAddressId(e.target.value);
                fetchAddressDetails(e);
              }}
              myRef={register({
                required: "Please select address",
              })}
              validationMsg={errors["address1"]?.message}
              defaultValue={addressId ?? formData?.address1}
            />
          </div>
          {/* {console.log(!!addressList.length, defaultAddress1?.address1)} */}
          {(!!addressList.length || !!defaultAddress1?.address1) && (
            <div>
              {(!!selectedAddress?.line_1 ||
                !!defaultAddress1?.txtHouseNumber) && (
                <div className="col-lg-12 mb-lg-3">
                  <AddressTextField
                    type="text"
                    placeholder="Address Line 1 *"
                    name="txtHouseNumber"
                    id="txtHouseNumber"
                    className="form-control"
                    dataId="txtHouseNumber"
                    autoComplete="off"
                    readonly={true}
                    validation={register({
                      required: "Please Enter Address Line 1",
                    })}
                    validationMsg={errors["txtHouseNumber"]?.message}
                    value={
                      selectedAddress?.line_1 ?? defaultAddress1?.txtHouseNumber
                    }
                  />
                </div>
              )}
              {(!!selectedAddress?.line_2 ||
                !!defaultAddress1?.txtAddress2) && (
                <div className="col-lg-12 mb-lg-3">
                  <AddressTextField
                    type="text"
                    placeholder="Address Line 2"
                    name="txtAddress2"
                    id="txtAddress2"
                    className="form-control w-100"
                    dataId="txtAddress2"
                    autoComplete="off"
                    readonly={true}
                    validation={register()}
                    value={
                      selectedAddress?.line_2 ?? defaultAddress1?.txtAddress2
                    }
                  />
                </div>
              )}
              {(!!selectedAddress?.county || !!defaultAddress1?.txtCounty) && (
                <div className="col-lg-12 mb-lg-3" ref={scrollToBottom}>
                  <AddressTextField
                    type="text"
                    placeholder="County"
                    name="txtCounty"
                    id="txtCounty"
                    className="form-control"
                    dataId="txtCounty"
                    autoComplete="off"
                    readonly={true}
                    validation={register()}
                    validationMsg=""
                    value={
                      selectedAddress?.county ?? defaultAddress1?.txtCounty
                    }
                  />
                </div>
              )}
              {(!!selectedAddress?.town || !!defaultAddress1?.txtTown) && (
                <div className="col-lg-12 mb-lg-3">
                  <AddressTextField
                    type="text"
                    placeholder="Town *"
                    name="txtTown"
                    id="txtTown"
                    className="form-control  hero-input"
                    dataId="txtTown"
                    autoComplete="off"
                    readonly={true}
                    validation={register({ required: "Please Enter Town" })}
                    validationMsg={errors["txtTown"]?.message}
                    value={selectedAddress?.town ?? defaultAddress1?.txtTown}
                  />
                </div>
              )}
              {(!!selectedAddress?.district ||
                !!defaultAddress1?.txtDistrict) && (
                <div className={`col-lg-12 mb-3 form-group adj_width`}>
                  <AddressTextField
                    type="text"
                    placeholder="District"
                    name="txtDistrict"
                    id="txtDistrict"
                    className="form-control"
                    dataId="txtDistrict"
                    autoComplete="off"
                    readonly={true}
                    validation={register()}
                    validationMsg={errors["txtDistrict"]?.message}
                  />
                </div>
              )}
              {(!!selectedAddress?.country ||
                !!defaultAddress1?.txtCountry) && (
                <div className="col-lg-12 mb-lg-3">
                  <AddressTextField
                    type="text"
                    placeholder="Country *"
                    name="txtCountry"
                    id="txtCountry"
                    className="form-control  hero-input"
                    dataId="txtCountry"
                    autoComplete="off"
                    readonly={true}
                    validation={register({
                      required: "Please Enter Country",
                    })}
                    validationMsg={errors["txtCountry"]?.message}
                    value={
                      selectedAddress?.country ?? defaultAddress1?.txtCountry
                    }
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default PostCode;
