import React from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const StuckInOverdraft = ({
    onSelectAnswer,
    backClick,
    setStuckinOverdraftbck,
    clsName
}) => {
    const optionClicked = async (e) => {
        GtmDataLayer({
            question: "stuck overdraft",
        });
        if (e.target.value == "293") {
            setStuckinOverdraftbck("293");
        }
        onSelectAnswer(e);
    };

    return (
        <>



            <div className={`qsSlider slidea9 ${clsName}`}>
                <h2 className="qs-title slide-up-now">
                    {" "}
                    I was stuck in my overdraft and didn't see a positive balance - do you agree???
                </h2>
                <div className="btn-option optinal-items optinal-items-payment slide-up-now  ">
                    <div className=" option_but mb-3">

                        <RadioButtonQuest
                            key="stuck1"
                            className="radio"
                            labelName="Always"
                            labelClassName="align-items-center next9"
                            onClick={optionClicked}
                            value="290"
                            name="stuckinoverdraft"
                            id="stuck1"
                            dataQuestId={105}
                            dataAnsId={290}
                            dataAnsLabel="Always"
                        />
                    </div>
                    <div className=" option_but mb-3">

                        <RadioButtonQuest
                            key="stuck2"
                            className="radio"
                            labelName="Sometimes"
                            labelClassName="align-items-center next9"
                            onClick={optionClicked}
                            value="291"
                            name="stuckinoverdraft"
                            id="stuck2"
                            dataQuestId={105}
                            dataAnsId={291}
                            dataAnsLabel="Sometimes"
                        />
                    </div>
                    <div className=" option_but mb-3">
                        <RadioButtonQuest
                            key="stuck3"
                            className="radio"
                            labelName="Rarely"
                            labelClassName="align-items-center next9"
                            onClick={optionClicked}
                            value="292"
                            name="stuckinoverdraft"
                            id="stuck3"
                            dataQuestId={105}
                            dataAnsId={292}
                            dataAnsLabel="Rarely"
                        />
                    </div>
                    <div className=" option_but mb-3">
                        <RadioButtonQuest
                            key="stuck4"
                            className="radio"
                            labelName="Never"
                            labelClassName="align-items-center next9"
                            onClick={optionClicked}
                            value="293"
                            name="stuckinoverdraft"
                            id="stuck4"
                            dataQuestId={105}
                            dataAnsId={293}
                            dataAnsLabel="Never"
                        />
                    </div>
                </div>
                <div className="mt-2 mb-5 text-center">
                    <div>
                        {" "}
                        <button className="back-btn mainbckBtn bi bi-chevron-left" id="back8" name="maintainedcreditbalance" onClick={backClick} type="button">
                           Back
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default StuckInOverdraft; 