import React from 'react'
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import GtmDataLayer from '../../../../Utility/GtmDataLayer';

const MaintainedCreditBalance = ({
    onSelectAnswer,
    backClick ,clsName}) => {
    const optionHandle = async (e) => {
        GtmDataLayer({
            question: "do you agree",
        });
        if (e.target.value === "288") {
            onSelectAnswer(e);
        } else if (e.target.value === "289") {
            onSelectAnswer(e);
        }
    }

    return (


        <div className={`qsSlider slidea5 ${clsName}`}>
            <h2 className="qs-title slide-up-now">
                {" "}
                I had not maintained a positive balance for a significant period of time, (including falling back into your overdraft shortly after pay day,) which ought to have made the bank aware of my financial difficulties - do you agree???</h2>
            <ul className="btn-option optinal-items  two-options  slide-up-now">
                <li>
                    <div className=" option_but ">
                        <RadioButtonQuest
                            key="cred1"
                            className="radio"
                            labelName="Agree"
                            labelClassName="align-items-center justify-content-center btn-next next5"
                            onClick={optionHandle}
                            value="288"
                            name="maintainedcreditbalance"
                            id="cred1"
                            dataQuestId={104}
                            dataAnsId={288}
                            dataAnsLabel="Agree"
                        />

                    </div>
                </li>
                <li>
                    <div className="option_but btn-next ">
                        <RadioButtonQuest
                            key="cred2"
                            className="radio"
                            labelName="Disagree"
                            labelClassName="align-items-center justify-content-center btn-next next5"
                            onClick={optionHandle}
                            value="289"
                            name="maintainedcreditbalance"
                            id="cred2"
                            dataQuestId={104}
                            dataAnsId={289}
                            dataAnsLabel="Disagree"
                        />
                    </div>
                </li>
            </ul>
            <div className="mt-2 mb-5 text-center">
                <div>
                    {" "}
                    <button className="back-btn mainbckBtn bi bi-chevron-left" onClick={backClick} type="button" name="struggledrepayments" id="back442">
                       Back
                    </button>
                </div>
            </div>
        </div>
    )
}

export default MaintainedCreditBalance;