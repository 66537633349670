import React, { useState, useRef } from "react";
import ssl from "../../../../assets/UFC_DC_V1/img/ssl.png";
import excellent_review from "../../../../assets/UFC_DC_V1/img/excellent-review.svg";
import bg_money_sml from "../../../../assets/UFC_DC_V1/img/bg-money-sml.png";
import logo_white from "../../../../assets/UFC_DC_V1/img/logo-white.png";
import { SalutationVAQUB4 } from "../../../../Constants/Questions";
import SelectBox from "../../../UI/SelectBox";
import Input from "./Input";
import PostCode from "./PostCode";
import DateOfBirth from "./DateOfBirth";
import { useEffect } from "react";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const PersonalDetails = ({
  showPersonalDetails,
  setShowPersonalDetails,
  setShowQuestion,
  setShowContactInfo,
  register,
  clearErrors,
  errors,
  getValues,
  trigger,
  setError,
  setShowLoader,
  setF_name,
}) => {
  const [dobday, setDobday] = useState("");
  const [dobmonth, setDobmonth] = useState("");
  const [dobyear, setDobyear] = useState("");
  const question_3 = getValues();
  const [notDobValid, setNotDobValid] = useState(false);

  const validateDateOfBirth = (dobDay, dobMonth, dobYear) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const isValidMonth = monthNames.includes(dobMonth);
    const isValidYear = dobYear >= 1910;

    if (
      isValidMonth &&
      isValidYear &&
      isValidDayInMonth(dobDay, monthNames.indexOf(dobMonth) + 1, dobYear)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const isValidDayInMonth = (day, month, year) => {
    const daysInMonth = [
      31,
      isLeapYear(year) ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ];
    return day >= 1 && day <= daysInMonth[month - 1];
  };

  const basicDetailsValidation = async (e) => {
    let errorFlag = 0;
    var lstSalutationResult = await trigger("lstSalutation");

    // var salutation = question_3.lstSalutation;
    var txtFNameResult;
    var txtLNameResult;
    var lstDobDayResult;
    var lstDobMonthResult;
    var lstDobYearResult;
    var lstPostResult;
    var address1Result;
    // var txtPhoneResult;
    // var txtEmailResult;
    if (lstSalutationResult) {
      txtFNameResult = await trigger("txtFName");
    } else {
      document.getElementById("lstSalutation").focus();
      return false;
    }
    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      document.getElementById("txtFName").focus();
      return false;
    }
    if (txtLNameResult) {
      lstPostResult = await trigger("txtPostCode");
    } else {
      document.getElementById("txtLName").focus();
      return false;
    }
    if (lstPostResult) {
      address1Result = await trigger("address1");
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (address1Result) {
      lstDobDayResult = await trigger("DobDay");
    } else {
      document.getElementById("address1").focus();
      return false;
    }
    if (lstDobDayResult) {
      lstDobMonthResult = await trigger("DobMonth");
    } else {
      document.getElementById("DobDay").focus();
      return false;
    }
    if (lstDobMonthResult) {
      lstDobYearResult = await trigger("DobYear");
    } else {
      document.getElementById("DobMonth").focus();
      return false;
    }
    if (lstDobYearResult) {
      // txtPhoneResult = await trigger("txtPhone");
    } else {
      document.getElementById("DobYear").focus();
      return false;
    }
    // if (txtPhoneResult) {
    //   txtEmailResult = await trigger("txtEmail");
    // } else {
    //   document.getElementById("txtPhone").focus();
    //   return false;
    // }
    // if (txtEmailResult) {
    // } else {
    //   document.getElementById("txtEmail").focus();
    //   return false;
    // }

    const dobIsValid = validateDateOfBirth(dobday, dobmonth, dobyear);
    if (!dobIsValid) {
      setNotDobValid(true);
      document.getElementById("DobDay").focus();
      document.getElementById("DobMonth").focus();
      document.getElementById("DobYear").focus();
      return false;
    } else {
      setNotDobValid(false);
    }

    if (
      !lstSalutationResult ||
      !txtFNameResult ||
      !txtLNameResult ||
      !lstDobDayResult ||
      !lstDobMonthResult ||
      !lstDobYearResult ||
      !lstPostResult ||
      // !txtPhoneResult ||
      // !txtEmailResult ||
      !dobIsValid
    ) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      // if (salutation == "Mrs" || salutation == "Ms" || salutation == "Miss" || salutation == "Dame" || salutation == "Lady") {
      //   seTitleQuest(true);
      // }
      // else {
      //   seTitleQuest(false);
      // }
      return true;
    } else {
      return false;
    }
  };

  const goBack = () => {
    setShowPersonalDetails("hide");
    window.scrollTo(0, 0);
    setShowQuestion("show");
  };

  const setFname = (e) => {
    let UcFname = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    setF_name(UcFname);
  };

  const nextSlide = async () => {
    const validcheck = await basicDetailsValidation();

    if (validcheck) {
      setShowLoader("show");
      GtmDataLayer({
        question: "personal information",
      });

      setTimeout(() => {
        setShowLoader("hide");
        setShowPersonalDetails("hide");
        window.scrollTo(0, 0);
        setShowContactInfo("show");
      }, 3000);
    }
    return false;
  };

  return (
    <>
      <section className={`banner  parallax-container ${showPersonalDetails}`}>
        <div className="d-lg-none d-md-none d-sm-block d-block money-bg-mobile">
          <img src={bg_money_sml} alt="" />
        </div>
        <div className="container">
          <div>
            <div className="brand">
              <div className="logo pt-3">
                <a href="index.html">
                  {" "}
                  <img src={logo_white} height="60" alt="" />
                </a>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className="container pt-5 px-lg-2 px-md-2 px-sm-0 px-0  ">
          <div className="inner-section  forms-body pb-5">
            <h2 className="  mb-3 main-title mt-lg-2 mt-md-2 mt-sm-2 mt-3">
              Personal Information
            </h2>
            <p>
              Please provide your personal information to ensure secure
              verification and completion of your claim check
            </p>

            <div className="form-wrap  mt-lg-4 mt-md-4 mt-sm-5 mt-4  ">
              <div className="row">
                <div className="col-lg-3">
                  <div className="mb-3">
                    <SelectBox
                      className="form-select select1 select-style"
                      OptionValue={SalutationVAQUB4}
                      name="lstSalutation"
                      id="lstSalutation"
                      onChange={(e) => {
                        clearErrors("lstSalutation");
                      }}
                      clearErrors={clearErrors}
                      myRef={register({ required: "Please select your title" })}
                      validationMsg={
                        errors.lstSalutation && errors.lstSalutation.message
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-4 ps-lg-1">
                  <div className="mb-3">
                    <Input
                      type="text"
                      placeholder="First Name"
                      className="form-control w-100"
                      name="txtFName"
                      id="txtFName"
                      rules={{
                        required: "Please enter your first name",
                        minLength: {
                          value: 3,
                          message: "Please enter valid first name",
                        },
                        pattern: {
                          value: /^[a-zA-Z\s]+$/,
                          message: "Your name is invalid. Please recheck",
                        },
                      }}
                      transform={(value) =>
                        value.replace(/^[ ]+/g, "").replace(/[^a-zA-Z\s]/g, "")
                      }
                      onChange={(e) => {
                        clearErrors("txtFName");
                        setFname(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-5 ps-lg-1">
                  <div className="mb-3">
                    <Input
                      type="text"
                      placeholder="Last Name"
                      className="form-control w-100"
                      name="txtLName"
                      id="txtLName"
                      rules={{
                        required: "Please enter your last name",
                        minLength: {
                          value: 3,
                          message: "Please enter valid last name",
                        },
                        pattern: {
                          value: /^[a-zA-Z\s]+$/,
                          message: "Your name is invalid. Please recheck",
                        },
                      }}
                      transform={(value) =>
                        value.replace(/^[ ]+/g, "").replace(/[^a-zA-Z\s]/g, "")
                      }
                    />
                  </div>
                </div>
              </div>

              <PostCode dobday={dobday} />

              <DateOfBirth
                setDobday={setDobday}
                setDobmonth={setDobmonth}
                setDobyear={setDobyear}
                notDobValid={notDobValid}
                register={register}
                errors={errors}
              />

              <div className="row pt-3 pb-3 mt-4">
                <div className="col-5" onClick={goBack}>
                  <button
                    type="button"
                    className="btn-comm2 btn-border   w-100  "
                    id="back2"
                  >
                    <span className="btntext">
                      {" "}
                      <i className="bi bi-chevron-left"></i> Back
                    </span>
                  </button>
                </div>
                <div className="col-7" onClick={nextSlide}>
                  <button
                    className="btn-comm2   w-100  personinfo"
                    type="button"
                  >
                    <span className="btntext"> Continue </span>
                  </button>
                </div>
              </div>
            </div>

            <div className=" mb-3 d-lg-flex justify-content-center  align-items-center text-center mt-5">
              <div className=" pe-lg-3 pe-md-3 ps-sm-0 pe-0 mb-lg-0 mb-md-0 mb-sm-4  mb-4">
                {" "}
                {/* <img src={excellent_review} alt="" />{" "} */}
              </div>
              <div>
                {" "}
                <img height="28" src={ssl} alt="" />{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PersonalDetails;
