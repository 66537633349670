import React from "react";
import RadioButtonQuest from '../../../UI/RadioButtonQuest';
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const Dependents = ({ onSelectAnswer, backClick, clsName, recieveIncomeback }) => {


    const optionClicked = async (e) => {
        
        GtmDataLayer({
            question: "dependents",
        });

        onSelectAnswer(e);
    };
    let toggle = "";
    if (recieveIncomeback == "267") {
        toggle = "monthlyincome";

    } else {
        toggle = "recieveanyincome";
    }

    return (
        <>
            <div className={`qsSlider slidea14 ${clsName}`}>
                <h2 className="qs-title slide-up-now">
                    {" "}
                    How many dependents did you have at that time?
                </h2>
                <div className="btn-option optinal-items  two-options  slide-up-now ">
                    {[
                        { id: "overdraft-limit0", label: "0", value: "7" },
                        { id: "overdraft-limit3", label: "1", value: "8" },
                        { id: "overdraft-limit4", label: "2", value: "9" },
                        { id: "overdraft-limit5", label: "3", value: "279" },
                        { id: "overdraft-limit6", label: "4", value: "280" },
                        { id: "overdraft-limit7", label: "4+", value: "281" },
                    ].map((options) => (
                        <div className=" option_but">
                            <RadioButtonQuest
                                key={options.id}
                                className="radio"
                                labelName={options.label}
                                labelClassName="align-items-center justify-content-center  next14"
                                onClick={optionClicked}
                                value={options.value}
                                name="dependents"
                                id={options.id}
                                dataQuestId={3}
                                dataAnsId={options.value}
                                dataAnsLabel={options.label}
                            />
                        </div>
                    ))}
                </div>
                <div className=" mb-5 text-center">
                    <div>
                        {" "}
                        <button
                            type="button"
                            name={toggle}
                            className="back-btn mainbckBtn bi bi-chevron-left"
                            id="back11"
                            onClick={backClick}>
                            Back
                        </button>
                    </div>
                </div>

            </div>
        </>
    );
};

export default Dependents;
