import React, { useState } from "react";
import EditInfo from "../../../../assets/EditDetails/img/editinfo.png";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import PrevPostCode1 from "./PrevPostCode1";
import PrevPostCode2 from "./PrevPostCode2";
import PrevPostCode3 from "./PrevPostCode3";
import { useHistory } from "react-router-dom";

const AddPreviousDetails = ({
  haveotherAddress,
  pCode,
  showFirstAddress,
  setShowFirstAddress,
  showSecondAddress,
  setShowSecondAddress,
  showThirdAddress,
  setShowThirdAddress,
  getValues,
  trigger,
  uuid,
  onSelectAnswer,
  setPrevAddress,
  PrevAddressSubmit,
  prev_address,
  CreditCheckCall
}) => {
  const [disableFirst, setDisableFirst] = useState(false);
  const [disableSecond, setDisableSecond] = useState(false);
  const [livedElseWhere, setLivedElseWhere] = useState(false);
  const [error, setError] = useState(false);
  const userDetails = getValues();
  const history = useHistory();

  const optionHandle = async (e) => {
    setLivedElseWhere(e.target.value);
    setError(false);
    
    if (e.target.value == 324) {
      setShowFirstAddress("show");
    } else {
      CreditCheckCall();
    }
    onSelectAnswer(e);
  };

  const pageChange = async (e) => {
    if (livedElseWhere) {
      const validcheck = await addressValidation();
      console.log(validcheck);
      
      const formDatas = getValues();
      // let prev_address = [];

      if (formDatas.txtPrevPostcode1 && formDatas.txtPrevAddress1A) {
        const prevAddress1 = {
          txtPrevAddress1:formDatas.txtPrevAddress1A,
          txtPrevPostcode:formDatas.txtPrevPostcode1,
          txtPrevAddressLine1: formDatas.txtPrevAddressLine1A,
          txtPrevAddressLine2: formDatas.txtPrevAddressLine2A,
          txtPrevTown: formDatas.txtPrevTown1,
          txtPrevCounty: formDatas.txtPrevCounty1,
          txtPrevCountry: formDatas.txtPrevCountry1,
          txtPrevDistrict: formDatas.txtPrevDistrict1,
          order: 1,
        };
        prev_address.push(prevAddress1);
      }
      if (formDatas.txtPrevPostcode2 && formDatas.txtPrevAddress1B) {
        const prevAddress2 = {
          txtPrevAddress1:formDatas.txtPrevAddress1B,
          txtPrevPostcode:formDatas.txtPrevPostcode2,
          txtPrevAddressLine1: formDatas.txtPrevAddressLine1B,
          txtPrevAddressLine2: formDatas.txtPrevAddressLine2B,
          txtPrevTown: formDatas.txtPrevTown2,
          txtPrevCounty: formDatas.txtPrevCounty2,
          txtPrevCountry: formDatas.txtPrevCountry2,
          txtPrevDistrict: formDatas.txtPrevDistrict2,
          order: 2,
        };
        prev_address.push(prevAddress2);
      }
      if (formDatas.txtPrevPostcode3 && formDatas.txtPrevAddress1C) {
        const prevAddress3 = {
          txtPrevAddress1:formDatas.txtPrevAddress1C,
          txtPrevPostcode:formDatas.txtPrevPostcode3,
          txtPrevAddressLine1: formDatas.txtPrevAddressLine1C,
          txtPrevAddressLine2: formDatas.txtPrevAddressLine2C,
          txtPrevTown: formDatas.txtPrevTown3,
          txtPrevCounty: formDatas.txtPrevCounty3,
          txtPrevCountry: formDatas.txtPrevCountry3,
          txtPrevDistrict: formDatas.txtPrevDistrict3,
          order: 3,
        };
        prev_address.push(prevAddress3);
      }
      console.log(prev_address);
      // setPrevAddress(prev_address);
      if (
        formDatas.txtPrevPostcode1 ||
        formDatas.txtPrevPostcode2 ||
        formDatas.txtPrevPostcode3
      ) {
        localStorage.setItem(
          "txtPrevPostcode",
          formDatas?.txtPrevPostcode3 ||
            formDatas?.txtPrevPostcode2 ||
            formDatas?.txtPrevPostcode1
        );
        localStorage.setItem(
          "txtPrevAddressLine1",
          formDatas?.txtPrevAddressLine1C ||
            formDatas?.txtPrevAddressLine1B ||
            formDatas?.txtPrevAddressLine1A
        );
        localStorage.setItem(
          "txtPrevAddressLine2",
          formDatas?.txtPrevAddressLine2C ||
            formDatas?.txtPrevAddressLine2B ||
            formDatas?.txtPrevAddressLine2A
        );
        localStorage.setItem(
          "txtPrevAddressLine3",
          formDatas?.txtPrevAddressLine3C ||
            formDatas?.txtPrevAddressLine3B ||
            formDatas?.txtPrevAddressLine3A
        );
        localStorage.setItem(
          "txtPrevTown",
          formDatas?.txtPrevTown3 ||
            formDatas?.txtPrevTown2 ||
            formDatas?.txtPrevTown1
        );
        localStorage.setItem(
          "txtPrevCounty",
          formDatas?.txtPrevCounty3 ||
            formDatas?.txtPrevCounty2 ||
            formDatas?.txtPrevCounty1
        );
        localStorage.setItem(
          "txtPrevCountry",
          formDatas?.txtPrevCountry3 ||
            formDatas?.txtPrevCountry2 ||
            formDatas?.txtPrevCountry1
        );
        localStorage.setItem(
          "txtPrevDistrict",
          formDatas?.txtPrevDistrict3 ||
            formDatas?.txtPrevDistrict2 ||
            formDatas?.txtPrevDistrict1
        );
      }
      // setPrevAddress(prev_address);
      if (validcheck) {
        const response = await PrevAddressSubmit();
      }
    } else {
      setError(true);
    }
  };

  const addressValidation = async (e) => {
    var txtPrevPostcodeResultA;
    var txtPrevAddress1ResultA;
    var txtPrevPostcodeResultB;
    var txtPrevAddress1ResultB;
    var txtPrevPostcodeResultC;
    var txtPrevAddress1ResultC;

    if (showFirstAddress == "show") {
      txtPrevPostcodeResultA = await trigger("txtPrevPostcode1");
      if (txtPrevPostcodeResultA) {
        txtPrevAddress1ResultA = await trigger("txtPrevAddress1A");
      } else {
        document.getElementById("txtPrevPostcode1").focus();
        return false;
      }
      if (!txtPrevAddress1ResultA) {
        document.getElementById("txtPrevAddress1A").focus();
        return false;
      } 
    }
    if (showSecondAddress == "show") {
      txtPrevPostcodeResultB = await trigger("txtPrevPostcode2");
      if (txtPrevPostcodeResultB) {
        txtPrevAddress1ResultB = await trigger("txtPrevAddress1B");
      } else {
        document.getElementById("txtPrevPostcode2").focus();
        return false;
      }
      if (!txtPrevAddress1ResultB) {
        document.getElementById("txtPrevAddress1B").focus();
        return false;
      }
    }
    if (showThirdAddress == "show") {
      txtPrevPostcodeResultC = await trigger("txtPrevPostcode3");
      if (txtPrevPostcodeResultC) {
        txtPrevAddress1ResultC = await trigger("txtPrevAddress1C");
      } else {
        document.getElementById("txtPrevPostcode3").focus();
        return false;
      }
      if (!txtPrevAddress1ResultC) {
        document.getElementById("txtPrevAddress1C").focus();
        return false;
      }
    }
    return true;
  };

  const addClick = (slide) => {
    if (slide == "showFirst") {
      setShowSecondAddress("show");
      setDisableFirst(true);
    } else if (slide == "showSecond") {
      setShowThirdAddress("show");
      setDisableSecond(true);
    }
  };

  const hideClick = (sts) => {

    if (sts == "FirstAdd") {
      setShowFirstAddress("hide");
    } else if (sts == "SecondAdd") {
      setShowSecondAddress("hide");
    } else if (sts == "ThirdAdd") {
      setShowThirdAddress("hide");
    }
  };

  return (
    <>
      <div className={`slide3 ${haveotherAddress}`}>
        <div className="form-header d-flex align-items-center">
          <div className="col-lg-2 col-3">
            <img src={EditInfo} alt="" />
          </div>
          <div className="col-lg-10 col-9 bor-l  align-self-center">
            <h4>
              We couldn't find <br className="d-block d-md-none" />
              <span> your agreements</span>
            </h4>
            <p>
              Don't worry, you can edit your details if they are incorrect, or
              add the lender if you know who provided your finance agreement.
            </p>
          </div>
        </div>
        <div className="drop-in ">
          <h5>
            Have you lived anywhere else other than {pCode} in the past 6 years
          </h5>
          <p>
            This is important information as it helps us find any past finance
            agreements.
          </p>
          <div className="choose_date col-lg-12 col-12">
            <div className="px-0 col-lg-12 d-flex gap-3">
              <div className="form-check ps-0">
                <RadioButtonQuest
                  key="Radios41date"
                  className="radio"
                  labelName="Yes"
                  labelClassName="form-check-label postyes"
                  onClick={optionHandle}
                  value="324"
                  name="Radioshere4date"
                  id="Radios41date"
                  dataQuestId={117}
                  dataAnsId={324}
                  dataAnsLabel="YES"
                />
              </div>
              <div className="form-check ps-0">
                <RadioButtonQuest
                  key="Radios42date"
                  className="radio"
                  labelName="No"
                  labelClassName="form-check-label postno"
                  onClick={optionHandle}
                  value="325"
                  name="Radioshere4date"
                  id="Radios42date"
                  dataQuestId={117}
                  dataAnsId={325}
                  dataAnsLabel="NO"
                />
              </div>
            </div>
            {error && (
              <span className="error_msg " id="err">
                Please Select an option
              </span>
            )}
          </div>
          <PrevPostCode1
            showAddress={showFirstAddress}
            addClick={addClick}
            disablests={disableFirst}
            hideClick={hideClick}
          />
          <PrevPostCode2
            showAddress={showSecondAddress}
            addClick={addClick}
            disablests={disableSecond}
            hideClick={hideClick}
          />
          <PrevPostCode3
            showAddress={showThirdAddress}
            addClick={addClick}
            disablests={disableSecond}
            hideClick={hideClick}
          />
          <hr />
          <div className="col-12 row justify-content-end">
            <div className="col-12 col-lg-8 pr-0">
              <button
                type="button"
                className="btn-banner btn-fullwidth mt-4"
                onClick={pageChange}
              >
                Save and Continue &gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPreviousDetails;
