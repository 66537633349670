import React, { useState } from 'react';
import TextField from '../../../UI/TextField';
import SelectBox from '../../../UI/SelectBox';
import { OpeningCreditLimitOption } from '../../../../Constants/Constants';
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import GtmDataLayer from '../../../../Utility/GtmDataLayer';
const OpeningCreditLimit = ({
    validation,
    getValues,
    trigger,
    saveInputAns,
    backClick,
    clsName
}) => {
    const [error, setError] = useState("");

    const handleClick = async (e) => {
        const values = getValues();
        const creditlimit = values["creditLimit"];
        let questionrslt = await trigger("creditLimit");
        // const re = /^[1-9]\d*$/;

        if (questionrslt) {
            if (creditlimit == "" || creditlimit == "£0" || creditlimit == "0" || creditlimit == null) {
                setError("Please enter a valid credit limit");
            } else {
                setError("");
                GtmDataLayer({
                    question: "opening limit",
                });
                saveInputAns(e);
            }
        } else {
            setError("Please enter credit limit");
        }
    }
    const dontRem = (e) => {
        if (e.target.name == "dontRemember") {
            GtmDataLayer({
                question: "opening limit",
            });
            saveInputAns(e);
        }
    }

    const handleChange = (e) => {
        const re = /^[1-9]\d*$/;
        if (e.target.value !== "" && !re.test(e.target.value)) {
            e.target.value = e.target.value.replace(/\D/g, "") * 1;
        }
        if (e.target.value !== "") {
            e.target.value =
                e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    };

    return (
        <div className={`qsSlider slidea ${clsName}`}>
            <h2 className="qs-title slide-up-now">
                {" "}
                What was your overdraft opening credit limit?
            </h2>
            <div className="btn-option optinal-items singleqs slide-up-now">
                <div className="mb-3  forms-body">
                    <SelectBox
                        type=""
                        className="form-select"
                        name="creditLimit"
                        OptionValue={OpeningCreditLimitOption}
                        onChange={() => setError("")}
                        myRef={validation({
                            required: true,
                        })}
                    />
                    {error && (
                        <span className="error_msg">{error}</span>
                    )}
                </div>
                {/* <div className=" option_but mb-1">
                    <input
                        type="checkbox"
                        id="Remember"
                        name="dontRemember"
                        data-quest-id={91}
                        onClick={dontRem}
                    />
                    <label
                        htmlFor="Remember"
                        className="btn-next"
                        tabIndex={0}
                        role="button"
                        aria-controls="swiper-wrapper-8f66e10493e4143ec"
                        aria-disabled="false"
                    >
                        <div> I Don't Remember</div>
                    </label>
                </div> */}

                <div className="option_but mb-1">

                    <RadioButtonQuest
                        key="dontRem"
                        className="radio"
                        labelName="I Don't Remember"
                        labelClassName=""
                        value=""
                        name="dontRemember"
                        id="dontRem"
                        dataQuestId={91}
                        dataAnsLabel=" I Don't Remember"
                        onClick={dontRem}
                    />
                </div>

            </div>
            <div className="row  mb-3 ">
                <div className="col-12">
                    <button className="btn-comm2 w-100  "
                        id="next4"
                        type="button"
                        name="openingCreditLimit"
                        onClick={handleClick}
                        data-quest-id={91}
                    >
                        {/* <span className="btntext"> Next </span> */}
                        Next
                    </button>
                </div>
            </div>
            <div className="mt-2 mb-5 text-center">
                <div>
                    {" "}
                    <button className="back-btn mainbckBtn bi bi-chevron-left"
                        type="button"
                        name="offerLoans"
                        id="back033"
                        onClick={backClick}
                    >
                        Back
                    </button>
                </div>
            </div>
        </div>
    )
}

export default OpeningCreditLimit;