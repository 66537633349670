import React, { useContext, useEffect, useRef, useState } from "react";
import ssl from "../../../../assets/UFC_DC_V1/img/ssl.png";
import excellent_review from "../../../../assets/UFC_DC_V1/img/excellent-review.svg";
import bg_money_sml from "../../../../assets/UFC_DC_V1/img/bg-money-sml.png";
import logo_white from "../../../../assets/UFC_DC_V1/img/logo-white.png";
import Email from "../../../UI/Email";
import Telephone from "../../../UI/Telephone";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const ContactInfo = ({
  showContactInfo,
  setShowPersonalDetails,
  setShowSignature,
  setShowContactInfo,
  register,
  clearErrors,
  errors,
  getValues,
  trigger,
  setError,
  setShowLoader,
  formSubmit
}) => {
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values["txtPhone"];
    const email = values["txtEmail"];

    if (phone !== "" && email !== "") {
      document.getElementById("personinfo").classList.add("anim_ylw");
    } else {
      document.getElementById("personinfo").classList.remove("anim_ylw");
    }
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone);
      if (getPhoneValidation.data.status_code === 0) {
        setShowLoader("hide");
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setShowLoader("hide");
        setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setShowLoader("hide");
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      } else {
        return 1;
      }
    }
  };
  const emailValidCheck = async () => {
    const values = getValues();
    const email = values["txtEmail"];
    const phone = values["txtPhone"];
    if (phone !== "" && email !== "") {
      document.getElementById("personinfo").classList.add("anim_ylw");
    } else {
      document.getElementById("personinfo").classList.remove("anim_ylw");
    }
    if (email !== "") {
      const getEmailValidation = await emailValidation(email);
      if (getEmailValidation.data.status_code === 1) {
        return 1;
      } else {
        setShowLoader("hide");
        setError("txtEmail", {
          type: "manual",
          message: getEmailValidation.data.message,
        });
      }
    }
  };

  const goBack = () => {
    setShowContactInfo("hide");
    window.scrollTo(0, 0);
    setShowPersonalDetails("show");
  };

  const nextSlide = async () => {
    try {
      var txtPhoneResult = await trigger("txtPhone");
      var txtEmailResult;

      if (txtPhoneResult) {
        txtEmailResult = await trigger("txtEmail");
      } else {
        document.getElementById("txtPhone").focus();
        return false;
      }
      if (txtEmailResult) {
      } else {
        document.getElementById("txtEmail").focus();
        return false;
      }
      setShowLoader("show");

      const phoneApiValid = await phoneValidCheck();
      const emailApiValid = await emailValidCheck();

      if (phoneApiValid && emailApiValid) {
        GtmDataLayer({
          question: "contact information",
        });
        setTimeout(() => {
          setShowLoader("hide");
          setShowContactInfo("hide");
          window.scrollTo(0, 0);
          setShowSignature(true);
          formSubmit('form');
        }, 3000);
      } else {
        setShowLoader("hide");
        console.log("Validation failed. Check errors.");
      }
    } catch (error) {
      setShowLoader("hide");
      console.error("Unexpected error during validation:", error);
    }
  };

  return (
    <>
      <section className={`banner  parallax-container ${showContactInfo}`}>
        <div className="d-lg-none d-md-none d-sm-block d-block money-bg-mobile">
          <img src={bg_money_sml} alt="" />
        </div>
        <div className="container">
          <div>
            <div className="brand">
              <div className="logo pt-3">
                <a href="index.html">
                  {" "}
                  <img src={logo_white} height="60" alt="" />
                </a>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className="container pt-5 px-lg-2 px-md-2 px-sm-0 px-0  ">
          <div className="inner-section  forms-body pb-5">
            <h2 className="text-success text-center excellent">Excellent!</h2>
            <p className="fw-semibold">
              Before we run the analysis, please confirm your details to keep
              you updated about the potential refunds.
            </p>
            <hr />
            <h2 className="mt-4  mb-3 main-title mt-lg-2 mt-md-2 mt-sm-2 mt-3">
              {" "}
              Contact Information
            </h2>

            <div className="form-wrap  mt-lg-4 mt-md-4 mt-sm-5 mt-4  ">
              <div className="row mt-2">
                <div className="col-lg-6 ">
                  <div className="mb-3">
                    {/* <input className="form-control w-100 field-secure" type="text"
                  placeholder="Mobile Number"/> */}

                    <Telephone
                      name="txtPhone"
                      id="txtPhone"
                      className="form-control w-100 field-secure"
                      placeholder="Mobile Number"
                      onBlur={phoneValidCheck}
                      onKeyPress={(e) =>
                        !/[0-9]+$/.test(e.key) && e.preventDefault()
                      }
                      maxlength="11"
                      validation={register({
                        pattern: {
                          value: /^[0-9]*$/i,
                          message: "Please enter a valid phone number",
                        },
                        required: "Please enter a valid phone number",
                      })}
                    />
                    {errors.txtPhone && (
                      <span className="error_msg " id="email_err">
                        {errors.txtPhone.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="mb-3">
                    <Email
                      name="txtEmail"
                      className="form-control w-100 field-secure"
                      id="txtEmail"
                      placeholder="Email Address"
                      onBlur={emailValidCheck}
                      validation={register({
                        required: "Please enter valid email address",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.txtEmail && (
                      <span className="error_msg " id="email_err">
                        {errors.txtEmail.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row pt-3 pb-3">
                <div className="col-5" onClick={goBack}>
                  <button
                    type="button"
                    className="btn-comm2 btn-border   w-100  "
                    id="back3"
                  >
                    <span className="btntext">
                      {" "}
                      <i className="bi bi-chevron-left"></i> Back
                    </span>
                  </button>
                </div>
                <div className="col-7" onClick={nextSlide}>
                  <button
                    type="button"
                    className="btn-comm2    w-100  "
                    id="personinfo"
                  >
                    <span className="btntext"> Continue </span>
                  </button>
                </div>
              </div>
            </div>

            <div className=" mb-3 d-lg-flex justify-content-center  align-items-center text-center mt-5">
              <div className=" pe-lg-3 pe-md-3 ps-sm-0 pe-0 mb-lg-0 mb-md-0 mb-sm-4  mb-4">
                {" "}
                {/* <img src={excellent_review} alt="" />{" "} */}
              </div>
              <div>
                {" "}
                <img height="28" src={ssl} alt="" />{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ContactInfo;
