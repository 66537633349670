import React from 'react'
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import GtmDataLayer from '../../../../Utility/GtmDataLayer';

const DidTakeFurtherBorrowing = ({
    onSelectAnswer,
    backClick,
    setStrugleRepaymentsBck, clsName }) => {

    const optionHandle = async (e) => {
        GtmDataLayer({
            question: "further borrowing",
        });
        setStrugleRepaymentsBck(e.target.value);
        if (e.target.value === "312") {
            onSelectAnswer(e);
        } else if (e.target.value === "313") {
            onSelectAnswer(e);
        }

    }
    return (


        <div className={`qsSlider slidea5 ${clsName}`}>
            <h2 className="qs-title slide-up-now">
                {" "}
                Did you take out further borrowing so that you could manage your finances?
            </h2>
            <ul className="btn-option optinal-items  two-options  slide-up-now">
                <li>
                    <div className=" option_but ">
                        <RadioButtonQuest
                            key="didtake1"
                            className="radio"
                            labelName="Yes"
                            labelClassName="align-items-center justify-content-center btn-next next5"
                            onClick={optionHandle}
                            value="312"
                            name="didtakefurtherborrowing"
                            id="didtake1"
                            dataQuestId={111}
                            dataAnsId={312}
                            dataAnsLabel="Yes"
                        />

                    </div>
                </li>
                <li>
                    <div className="option_but btn-next ">
                        <RadioButtonQuest
                            key="didtake2"
                            className="radio"
                            labelName="No"
                            labelClassName="align-items-center justify-content-center btn-next next5"
                            onClick={optionHandle}
                            value="313"
                            name="didtakefurtherborrowing"
                            id="didtake2"
                            dataQuestId={111}
                            dataAnsId={313}
                            dataAnsLabel="No"
                        />
                    </div>
                </li>
            </ul>
            <div className="mt-2 mb-5 text-center">
                <div>
                    {" "}
                    <button className="back-btn mainbckBtn bi bi-chevron-left" onClick={backClick} type="button" name="incmAndexp" id="back442">
                        Back
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DidTakeFurtherBorrowing;