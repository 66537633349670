import { Api } from "../api/Api";

export const useAddressApi = () => {
  const addressCheck = async (
    visitor_parameters,
    data
  ) => {
    const response = await Api.post("api/v1/address-check", {
        visitor_parameters,
        data
    });
    return response;
  };
  return { addressCheck };
};
