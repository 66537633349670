import React, { useState } from "react";
import RadioButtonQuest from '../../../UI/RadioButtonQuest';
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const OfferLoans = ({ onSelectAnswer, backClick,clsName,highestNumBck }) => {


    const optionHandle = async (e) => {
        GtmDataLayer({
            question: "provide loans",
        });
        onSelectAnswer(e);
    }
    let toggle="";
    if(highestNumBck.some(option => option.id === 308)){
        toggle = "highestNum";
    } else {
        toggle = "applythisFollowing";
    }

    return (
        <>
            <div className={`qsSlider slidea3 ${clsName}`}>
                <h2 className="qs-title slide-up-now">
                    {" "}
                    Did the overdraft provider (your bank) offer you one of their
                    own loans to reduce your overdraft?
                </h2>
                <div className="btn-option optinal-items singleqs slide-up-now">
                    <div className=" option_but mb-3">
                        <RadioButtonQuest
                            key="off1"
                            className="radio"
                            labelName="Yes, and I accepted"
                            onClick={optionHandle}
                            value="309"
                            name="offerLoans"
                            id="off1"
                            dataQuestId={110}
                            dataAnsId={309}
                            dataAnsLabel="Yes, and I accepted"
                        />
                    </div>
                    <div className=" option_but  mb-3">
                        <RadioButtonQuest
                            key="off2"
                            className="radio"
                            labelName="Yes, and I rejected"
                            onClick={optionHandle}
                            value="310"
                            name="offerLoans"
                            id="off2"
                            dataQuestId={110}
                            dataAnsId={310}
                            dataAnsLabel="Yes, and I rejected"
                        />
                    </div>
                    <div className=" option_but ">
                        <RadioButtonQuest
                            key="off3"
                            className="radio"
                            labelName="No, they did not"
                            onClick={optionHandle}
                            value="311"
                            name="offerLoans"
                            id="off3"
                            dataQuestId={110}
                            dataAnsId={311}
                            dataAnsLabel="No, they did not"
                        />
                    </div>
                </div>
                <div className="mt-2 mb-5 text-center">
                    <div>
                        {" "}
                        <button type="button" className="back-btn mainbckBtn bi bi-chevron-left" id="back02" name={toggle} onClick={backClick}>
                             Back
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OfferLoans;
