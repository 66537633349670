import { useState } from "react";
import { Api } from "../api/Api";

export const usePageLoad = () => {
  const [calledOnce, setCalledOnce] = useState(false);

  const savePageLoad = async (
    visitor_parameters,
    data,
    message_type,
    page_name,
    query_string,
    visitor_data,
    additionalData = {}
  ) => {
    const response = await Api.post("api/v1/data-ingestion-pipeline", {
      visitor_parameters,
      ...(Object.keys(data).length != 0 && { data }),
      message_type,
      page_name,
      query_string,
      visitor_data,
      ...additionalData,
    }).catch(function () {
      return {
        data: {
          status: "Success",
          error: true,
        },
      };
    });
    setCalledOnce(true);
    return response;
  };
  return { savePageLoad, calledOnce };
};
