import React, { useRef, useState } from "react";
import CheckBox from "../../../UI/CheckBox";
import Button2 from "../../../UI/Button2";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
const ApplythisFollowing = ({
    clsName,
    onSelectAnswer,
    questArr,
    optionSelected,
    backClick,
    setHighestNumBck
}) => {
    const [showError36, setshowError36] = useState("hide");

    const [btndisabled, setBtndisabled] = useState(false);
    const checkboxRefs = useRef({});

    const labelclicked = async (e) => {
     if(e.target.name =="applythisFollowing"){
        setHighestNumBck(optionSelected);
        let selectedCount = optionSelected.length;
        if (selectedCount == 0) {
            setBtndisabled(false);
            setshowError36("show");
        } else {
            console.log(e.target.name);
            GtmDataLayer({
                question: "overdraft application",
            });
            setBtndisabled(true);
            onSelectAnswer(e);
        }
    }
    };

    const onSelectOption = (e) => {
        console.log(e.target.value);
        if (e.target.checked === true) {
            setshowError36("hide");
            optionSelected.push({ id: +e.target.value });
            // checkBoxDisable(e.target.value); 
        } else {
            let deselect = e.target.value;
            const decre = optionSelected.map((OptionArray, index) => {
                if (deselect == OptionArray.id) {
                    optionSelected.splice(index, 1);
                    if (optionSelected.length == 0) {
                        checkBoxEnable();
                    }
                }
            });
        }
    };

    const handleChange = (e) => {
        // const re = /^[0-9\b]+$/;
        const re = /^[1-9]\d*$/;
        if (e.target.value !== "" && !re.test(e.target.value)) {
            e.target.value = e.target.value.replace(/\D/g, "") * 1;
        }
        e.target.value = e.target.value
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    let checkArray = questArr.answers;

    const questionId = questArr.questionId ? questArr.questionId : 0;

    const lenderCheckBox = checkArray.map((checArr, index) => {
        let heightcls = checArr.value == '104' ? 'heightnewcls' : '';
        return (
            <div className="option_but mb-3" key={checArr.value} ref={(e) => checkboxRefs.current[checArr.value] = e}>
                <div className="odnewscss">
                    <CheckBox
                        dataId={`applythisFollowing${checArr.value}`}
                        name="chkBankName[]"
                        onChange={onSelectOption}
                        className=""
                        CheckBoxText={'<span></span>' + checArr.label}
                        value={checArr.value}
                        labelClassName="align-items-center btn-next tick-check-box"
                    />
                </div>
            </div>
        );
    });



    const checkBoxEnable = () => {
        checkArray.map((checArr, index) => {
            checkboxRefs.current[checArr.value].childNodes[0].disabled = false;
            checkboxRefs.current[checArr.value].childNodes[1].classList.remove("disabledcheckbox");
        })
    }

    return (
        <>

            <div className={`qsSlider slidea ${clsName}`}>
                <h2 className="qs-title slide-up-now">
                    {" "}
                    While having an overdraft did any of the following apply<br/>
                </h2>
                <p className="lbl-fnt"> 
                    <i>(Select all that applies)</i>
                </p>

                <div className="btn-option optinal-items optinal-items-payment pb-3  slide-up-now  ">
                    {lenderCheckBox}

                </div>
                <span className={`error_msg ${showError36}`} id="email_err">
                    Please select at least one
                </span>

                <div className="row mb-3 ">
                    <div className="col-12 ">
                    <button
                        type="button"
                        className="btn-comm2 w-100"
                        id="q109"
                        onClick={labelclicked}
                        name="applythisFollowing" // Fixed name
                        data-quest-id={109}
                    >
                        {/* <span className="btntext">Continue</span> */}
                        Continue
                    </button>
                        
                    </div>
              
                </div>
                <div className="mt-2 mb-5 text-center">
                    <div>
                        {" "}
                        <button
                            type="button"
                            id="back011"
                            className="back-btn mainbckBtn bi bi-chevron-left"
                            name="OccasionalUnforeseenEmergency" // Static name for back button
                            onClick={backClick}
                        >
                             Back
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ApplythisFollowing;