import React, { useState, useEffect } from "react";
import { DobYear, DobMonth, DobDay } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";

const DateOfBirth = ({
  errors,
  register,
  setDobday,
  setDobmonth,
  setDobyear,
  notDobValid,
}) => {

  const removeClass = (e) => {
    setDobday(e.target.value);
    document.getElementById("DobDay").classList.remove("pulse2");
    document.getElementById("DobDay").classList.remove("shadow2");
  };

  return (
    <div className="date-birth" id="date-birth">
      {/* <div className=" mt-4"> */}
      {/* <fieldset className="p-2 px-2"> */}
      <h3 className="title mb-3 mt-4">Date Of Birth</h3>
      <div className="row">

        <div className="col-4">
          <div className="mb-3">
            <SelectBox
              className="w-100  form-select"
              OptionValue={DobDay}
              // defaultValue={dob_day}
              name="DobDay"
              onChange={removeClass}
              id="DobDay"
              myRef={register({
                required: "Please select date",
              })}
              validationMsg={errors.DobDay && errors.DobDay.message}
            />
            <i className="tick fa" style={{ display: "none" }}></i>
          </div>
        </div>

        <div className="col-4">
          <div className="mb-3">
          <SelectBox
              className="w-100  form-select"
              OptionValue={DobMonth}
              // defaultValue={dob_month}
              onChange={(e) => setDobmonth(e.target.value)}
              name="DobMonth"
              id="DobMonth"
              myRef={register({
                required: "Please select month",
              })}
              validationMsg={errors.DobMonth && errors.DobMonth.message}
            />
            <i className="tick fa" style={{ display: "none" }}></i>
          </div>
        </div>

        <div className="col-4">
          <div className="mb-3">
          <SelectBox
              className="w-100  form-select"
              OptionValue={DobYear}
              // defaultValue={dob_yr}
              name="DobYear"
              id="DobYear"
              onChange={(e) => setDobyear(e.target.value)}
              myRef={register({
                required: "Please select year",
              })}
              validationMsg={errors.DobYear && errors.DobYear.message}
            />
            <i className="tick fa" style={{ display: "none" }}></i>
          </div>
        </div>
          <span id="dob_final_err" className="error_msg"></span>

          {notDobValid && (
            <span id="dob_final_err" className="error_msg">
              Invalid date of birth provided.
            </span>
          )}

      </div>
    </div>
  );
};

export default DateOfBirth;
