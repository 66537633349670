import React from 'react'
import LogoWhite from "../../../../assets/EditDetails/img/logo-white.png";

const Footer = () => {
    return (
        <div>
            <footer className="py-lg-5 py-md-5 py-sm-4 py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 tex-lg-start mb-3 text-md-start text-sm-center text-center">
                            <div className="ft-logo"> <img src={LogoWhite} height="70px" alt="" /></div>
                        </div>
                        <div className="col-lg-8">
                            <p>TMS Legal Ltd is a limited company registered in England and Wales under company no: 10315962 Its registered office is Arena, 9 Nimrod Way, Ferndown, BH21 7UH It is authorised and regulated by the Solicitors Regulation Authority, Number: 645041</p>
                            <ul className="m-0 p-0">
                                <li><a href>Privacy and Cookie Policy</a></li>
                                <li className="bord"><a href>Terms and Conditions</a></li>
                                <li className="ps-2"><a href>Complaints Procedure</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer