import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import ssl from "../../../../assets/UFC_DC_V1/img/ssl.png";
import excellent_review from "../../../../assets/UFC_DC_V1/img/excellent-review.svg";
import bg_money_sml from "../../../../assets/UFC_DC_V1/img/bg-money-sml.png";
import logo_white from "../../../../assets/UFC_DC_V1/img/logo-white.png";
import SignatureCanvas from "react-signature-canvas";
import { CheckUUID } from "../../../../Utility/CheckUUID";
import { useSignature } from "../../../../Hooks/useSignature";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { queryString } from "../../../../Utility/QueryString";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
import InputButton from "../../../UI/InputButton";
import { useFormContext } from "react-hook-form";
import CheckBox2 from "./Checkbox2";
import Loa from "./Loa";
import Tob from "./Tob";
import Decl from "./Decl";
import Vq from "./Vq";
import Dsar from "./Dsar";
import Accordion from 'react-bootstrap/Accordion';


const Signature = ({
  showSignature,
  f_name,
  formSubmit,
  setSignatureDataURL,
  signPadRef,
  setAgreement,
  estimatedRefund,
  unaffordableRefund
}) => {
  const initialState = {
    isChecked: false,
    isAgreed: false,
    isCanvasEmpty: false,
    isValid: false,
  };
  const signatureReducer = (state, action) => {
    switch (action.type) {
      case "signPadChange": {
        return {
          ...state,
          isCanvasEmpty: action.payload.value,
        };
      }
      // case "privacyAgreed": {
      //   return {
      //     ...state,
      //     isAgreed: action.payload.value,
      //   };
      // }
      case "validSignature": {
        return {
          ...state,
          isValid: action.payload.value,
        };
      }
      default:
        return state;
    }
  };

  const splitForm = useRef(null);
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveSignature, isLoading } = useSignature();
  const [isChecked, setIsChecked] = useState(true);
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  // const signPadRef = useRef();
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const message_type = "signature_store";
  const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
  const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
  const signatureWrapRef = useRef(null);
  // const topPortion = useRef(null);

  const handleClick = async (e) => {
    // const validcheck = await basicDetailsValidation();
    // if (validcheck) {
    try {
      e.preventDefault();
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        signatureWrapRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }
      // if (!isChecked) {
      //   setAgreement(false);
      //      signatureWrapRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
      //      return dispatch({ type: "privacyAgreed", payload: { value: true } });
      // }
      const rpdata = signPad.toData();
      var signLength = 0;
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength > 13) {
        GtmDataLayer({
          question: "signature",
        });
        const canvasDataURL = signPadRef.current
        .getCanvas()
        .toDataURL("image/png");
        setSignatureDataURL(canvasDataURL);
        formSubmit('signature');
      } else {
        return dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (e) {
      console.warn(e);
    }

    // }
  };

  useEffect(() => {
    if (!isChecked) {
      setAgreement(false);
      // dispatch({ type: "privacyAgreed", payload: { value: false } });
    } else {
      setAgreement(true);
    }
  }, [isChecked]);

  useEffect(() => {
    function updateCanvasDimensions() {
      if (signatureWrapRef.current) {
        setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
        setSignatureWrapHeight(signatureWrapRef.current.clientHeight);
      }
    }

    // Initial update
    updateCanvasDimensions();

    // Resize observer for dynamic updates
    const resizeObserver = new ResizeObserver(updateCanvasDimensions);
    if (signatureWrapRef.current) {
      resizeObserver.observe(signatureWrapRef.current);
    }

    return () => {
      if (signatureWrapRef.current) {
        resizeObserver.unobserve(signatureWrapRef.current);
      }
    };
  }, []);

  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
  };

  return (
    <>
      <section className={`banner  parallax-container ${showSignature}`}>
        <div className="d-lg-none d-md-none d-sm-block d-block money-bg-mobile">
          <img src={bg_money_sml} alt="" />
        </div>
        <div className="container">
          <div>
            <div className="brand">
              <div className="logo pt-3">
                <a href="index.html">
                  {" "}
                  <img src={logo_white} height="60" alt="" />
                </a>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className="container pt-5 px-lg-2 px-md-2 px-sm-0 px-0  ">
          <div className="inner-section  forms-body forms-body1  pb-3 sginature  mb-0 pb-0  ">
            <div className="p-2 d-flex  text2-info justify-content-center mb-4 rounded-2">
              <div className="pe-2">
                <i className="bi bi-info-circle"></i>
              </div>
              <div>Sign below to initiate your claim</div>
            </div>

            <h1 className="  text-greatnews">
              {" "}
              <span className="text-danger">GREAT NEWS,</span>
              {" "}{f_name ? f_name : ""}
            </h1>
            <div className="fw-bold ownedupto mb-2">
              You could be owed <span>£1000’s</span>
            </div>
            <p>
              {" "}
              Please provide your signature as in records. Your signature will
              authorise us to draft the claim application on your behalf.
            </p>
          </div>
        </div>
        <div className="container    px-lg-2 px-md-2 px-sm-0 px-0  ">
          <div className="inner-section  forms-body forms-body2 pb-5 sginature signboxsection  pt-0 px-lg-5 px-md-5 px-sm-0 px-0">
            <div className="signbox position-relative" ref={signatureWrapRef}>
              <SignatureCanvas
                canvasProps={{
                  id: "myID",
                  className: "w-100",
                  width: signatureWrapWidth,
                  height: 350,
                }}
                ref={signPadRef}
                onEnd={() => {
                  if (!signPadRef.current.isEmpty()) {
                    dispatch({
                      type: "signPadChange",
                      payload: { value: false },
                    });
                    dispatch({
                      type: "validSignature",
                      payload: { value: false },
                    });
                  }
                }}
              />
              <input
                type="button"
                id=""
                name=""
                className="sign-clear position-absolute border-0 rounded-2 px-4 py-2 text-white"
                value="Clear"
                onClick={clearSign}
              />
            </div>
            {state.isCanvasEmpty && (
              <div
                className="sign_msg col-12 text-center sign_msg mb-3"
                id="signatures_required"
              >
                Signature is required!
              </div>
            )}
            {state.isValid && (
              <div
                className="sign_msg col-12 text-center sign_msg"
                id="signatures_valid"
              >
                Draw valid signature!
              </div>
            )}
            <div className="px-lg-0 px-md-0 px-sm-4 px-4 ">
              <div className="d-flex gap-2 checkbox-set mb-3 mt-3">
                <CheckBox2
                  dataId="checkbox1"
                  anchorText=""
                  anchorStyle=""
                  href=""
                  // onChange={(e) => setIsChecked(!isChecked)}
                  onChange={() => {
                    setIsChecked(!isChecked);
                    dispatch({
                      type: "privacyAgreed",
                      payload: { value: isChecked },
                    });
                  }}
                  className="shadow-none check-input me-2"
                  value={isChecked}
                  checked={isChecked}
                  CheckBoxText="I agree to the processing of my personal data as outlined below."
                />
              </div>
              <div className="bg-white">
                <div className="row pt-3 pb-3 mt-4">
                  <div className="col-12 d-none d-md-block" onClick={handleClick}>
                    <button
                      type="button"
                      
                      className="btn-sign   fw-bold  w-100  personinfo"
                    >
                      <span className="btntext"> Submit </span>
                    </button>
                  </div>
                </div>
              </div>

              {/* {state?.isAgreed && (
                <div
                  className="sign_msg col-12 text-center sign_msg"
                  id="checkbox_valid"
                >
                  "Please tick to show your authorisation for us to proceed!"
                </div>
              )} */}

              <div className="sign-blowtext">
                <div className="text-cp">
                  <p className="fw-semibold ">
                    We respect your privacy and give you control over your
                    personal data. Please review the information below before
                    giving your consent.
                  </p>
                  <p className="fw-semibold">
                    {" "}
                    By ticking the box, you agree to the following :{" "}
                  </p>
                </div>

                <p className="sig-title mb-1">Consent for Data Processing</p>
                <p>
                  You consent to TMS Legal using your data to represent you in
                  your Responsible Lending claim.
                </p>
                {(estimatedRefund == "318" && unaffordableRefund == "320") && (
                  <div>
                    <p className="sig-title mb-1">Third-Party Sharing</p>
                    <p>
                      You agree that TMS Legal may share your data with third-party
                      service providers, including My Claim Group, solely to
                      make claims on my behalf about all car finance agreements
                    </p>
                  </div>
                )}

                <p className="sig-title mb-1">Withdrawal of Consent</p>
                <p>
                  You can withdraw consent at any time by contacting us.
                  Withdrawal of consent will not affect the legality of any
                  processing carried out before your request.
                </p>

                <p className="sig-title mb-1">Consent for Refund Estimate</p>
                <p>
                  By proceeding, you consent to a free online scan of your
                  financial information to calculate your estimated refund for
                  overdraft facilities. Rest assured, this process is completely
                  secure.
                </p>

                <p className="sig-title mb-1">Optional Consent</p>
                <p>
                  Your consent is voluntary and not a condition for accessing
                  our services.
                </p>

                 <Accordion>
                    <Accordion.Item eventKey="0">
                       <Accordion.Header>View Claim Documents</Accordion.Header>
                          <Accordion.Body>
                            <div className="mt-2 d-flex">
                              <i className="bi bi-box-arrow-up-right pt-2"></i>
                              <Loa/>
                            </div>
                            <div className="mt-2 d-flex">
                              <i className="bi bi-box-arrow-up-right pt-2"></i>
                                <Vq/>
                            </div>
                            <div className="mt-2 d-flex">
                              <i className="bi bi-box-arrow-up-right pt-2"></i>
                                <Tob/>
                            </div>
                            <div className="mt-2 d-flex">
                              <i className="bi bi-box-arrow-up-right pt-2"></i>
                                <Decl/>
                            </div>
                            <div className="mt-2 d-flex">
                              <i className="bi bi-box-arrow-up-right pt-2"></i>
                                <Dsar/>
                            </div>
                          </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
              </div>

              <div className="bg-white">
                <div className="row pt-3 pb-3 mt-4">
                  <div className="col-12 stickyBtnV2 d-block d-md-none" onClick={handleClick}>
                    <button
                      type="button"
                      
                      className="btn-sign   fw-bold  w-100  personinfo btn-"
                    >
                      <span className="btntext"> Submit </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className=" mb-3 d-lg-flex justify-content-center  align-items-center text-center mt-5">
              <div className=" pe-lg-3 pe-md-3 ps-sm-0 pe-0 mb-lg-0 mb-md-0 mb-sm-4  mb-4">
                {" "}
                {/* <img src={excellent_review} alt="" />{" "} */}
              </div>
              <div>
                {" "}
                <img height="28" src={ssl} alt="" />{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Signature;
