import React from 'react'
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import GtmDataLayer from '../../../../Utility/GtmDataLayer';

const StruggledRepayments = ({
    onSelectAnswer,
    backClick,
    strugleRepaymentsBck,
    clsName
    }) => {

    const optionHandle = async (e) => {

        GtmDataLayer({
            question: "essential repayments",
        });

        if (e.target.value === "286") {
            onSelectAnswer(e);
        } else if (e.target.value === "287") {
            onSelectAnswer(e);
        }

    }
    let toggle;
    if (strugleRepaymentsBck == "313") {
        toggle = 'didtakefurtherborrowing';
      } else if(strugleRepaymentsBck == "312") {
        toggle = 'furtherborrowing';
      }
  return (
   
<div className={`qsSlider slidea5 ${clsName}`}>
<h2 className="qs-title slide-up-now">
    {" "}
    Did you struggle to meet your essential repayments while being in your overdraft?
</h2>
<ul className="btn-option optinal-items  two-options  slide-up-now">
    <li>
        <div className=" option_but ">
            <RadioButtonQuest
                key="strrep1"
                className="radio"
                labelName="Yes"
                labelClassName="align-items-center justify-content-center btn-next next5"
                onClick={optionHandle}
                value="286"
                name="struggledrepayments"
                id="strrep1"
                dataQuestId={103}
                dataAnsId={286}
                dataAnsLabel="Yes"
            />

        </div>
    </li>
    <li>
        <div className="option_but btn-next ">
            <RadioButtonQuest
                key="strrep2"
                className="radio"
                labelName="No"
                labelClassName="align-items-center justify-content-center btn-next next5"
                onClick={optionHandle}
                value="287"
                name="struggledrepayments"
                id="strrep2"
                dataQuestId={103}
                dataAnsId={287}
                dataAnsLabel="No"
            />
        </div>
    </li>
</ul>
<div className="mt-2 mb-5 text-center">
    <div>
        {" "}
        <button className="back-btn mainbckBtn bi bi-chevron-left" onClick={backClick} type="button"  name={toggle} id="back442">
        Back
        </button>
    </div>
</div>
</div>
  )
}

export default StruggledRepayments