import React from "react";
import loader from "../../../../assets/UFC_DC_V1/img/loader-review2.svg";

const Loader = ({showLoader}) => {
  return (
    <>
      <div class={`text-center loader loader1 align-content-center fade-in ${showLoader == 'show' ? 'show' : 'hide'}`}>
        <div class="inner py-5">
          <div class="my-3">
            <img height="70" class="" src={loader} alt="" />
          </div>
          <h2 class="px-4"> Please wait...</h2>
        </div>
      </div>
    </>
  );
};

export default Loader;
