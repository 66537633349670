import React, { useState } from "react";
import icon01 from "../../../../assets/UFC_DC_V1/img/icon01.svg";
import icon02 from "../../../../assets/UFC_DC_V1/img/icon02.svg";
import icon03 from "../../../../assets/UFC_DC_V1/img/icon03.svg";

const ContentSection = () => {
  return (
    <>
      <section className="welcome-highlights  ">
        <div className="container">
          <div className="panel rounded-4 p-5  ">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-lg-0 mb-md-0 mb-sm-3  mb-3 fadein-eff">
                <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                  <img src={icon01} alt="" />
                  <h2 className="mt-3">
                    {" "}
                    Get your Mis-sold Loan Fees and Charges back
                  </h2>
                  <p>
                    The lender needed to make sure you were able to repay your
                    overdraft within a reasonable period. Therefore, it might
                    not have been fair or reasonable to continue charging
                    interest and charges over the long term.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-lg-0 mb-md-0 mb-sm-3 fadein-eff">
                <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                  <img src={icon02} alt="" />
                  <h2 className="mt-3">
                    Your Claim Fought by Financial &amp; Legal Experts
                  </h2>
                  <p>
                    We're ahead of the field, drawing on years of financial
                    services and legal expertise for a strong claim
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-lg-0 mb-md-0 mb-sm-3 fadein-eff">
                <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                  <img src={icon03} alt="" />
                  <h2 className="mt-3">Respecting Your Information Security</h2>
                  <p>
                  We take security very seriously and follow the rules of the Data Protection Act.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ContentSection;
