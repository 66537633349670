import React from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const OverLimitCharges = ({
    onSelectAnswer,
    backClick,
    clsName
}) => {
    const optionClicked = async (e) => {
        GtmDataLayer({
            question: "overlimit charges",
        });
        onSelectAnswer(e);
    };

    return (
        <>
        <div className={`qsSlider slidea10 ${clsName}`}>
        <h2 className="qs-title slide-up-now">
                    {" "}
                    Did you receive overlimit charges?
                </h2>
                <div className="btn-option optinal-items optinal-items-payment pb-3  slide-up-now ">
                    <div className=" option_but mb-3">

                        <RadioButtonQuest
                            key="overch1"
                            className="radio"
                            labelName="Always"
                            labelClassName="align-items-center next10"
                            onClick={optionClicked}
                            value="261"
                            name="overlimit"
                            id="overch1"
                            dataQuestId={97}
                            dataAnsId={261}
                            dataAnsLabel="Always"
                        />
                    </div>
                    <div className=" option_but mb-3">

                        <RadioButtonQuest
                            key="overch2"
                            className="radio"
                            labelName="Sometimes"
                            labelClassName="align-items-center next10"
                            onClick={optionClicked}
                            value="262"
                            name="overlimit"
                            id="overch2"
                            dataQuestId={97}
                            dataAnsId={262}
                            dataAnsLabel="Sometimes"
                        />
                    </div>
                    <div className=" option_but mb-3">

                        <RadioButtonQuest
                            key="overch3"
                            className="radio"
                            labelName="Rarely"
                            labelClassName="align-items-center next10"
                            onClick={optionClicked}
                            value="263"
                            name="overlimit"
                            id="overch3"
                            dataQuestId={97}
                            dataAnsId={263}
                            dataAnsLabel="Rarely"
                        />
                    </div>
                    <div className=" option_but mb-3">
                        <RadioButtonQuest
                            key="overch4"
                            className="radio"
                            labelName="Never"
                            labelClassName="align-items-center next10"
                            onClick={optionClicked}
                            value="264"
                            name="overlimit"
                            id="overch4"
                            dataQuestId={97}
                            dataAnsId={264}
                            dataAnsLabel="Never"
                        />
                    </div>
                </div>
                <div className="mt-2 mb-5 text-center">
                    <div>
                        {" "}
                        <button className="back-btn mainbckBtn bi bi-chevron-left" onClick={backClick} type="button" name="oftenexceedCredit" id="back9">
                            Back
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OverLimitCharges; 