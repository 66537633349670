import React from 'react'
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import GtmDataLayer from '../../../../Utility/GtmDataLayer';

const EverIncreCredLimit = ({
    setEverIncreased,
    onSelectAnswer,
    backClick ,
    clsName}) => {
    const optionHandle = async (e) => {
        GtmDataLayer({
            question: "limit increase",
        });
 
        if (e.target.value === "247") {
            setEverIncreased("247");
            onSelectAnswer(e);
        } else if (e.target.value === "248") {
            setEverIncreased("248");
            onSelectAnswer(e);
        }

    }
    return (
        <div className={`qsSlider slidea5 ${clsName}`}>
        <h2 className="qs-title slide-up-now">
            {" "}
            Was your credit limit ever increased?
        </h2>
        <ul className="btn-option optinal-items  two-options  slide-up-now">
            <li>
                <div className=" option_but ">
                    <RadioButtonQuest
                            key="everinc1"
                            className="radio"
                            labelName="Yes"
                            labelClassName="align-items-center justify-content-center btn-next next5"
                            onClick={optionHandle}
                            value="247"
                            name="everincresedCredLimit"
                            id="everinc1"
                            dataQuestId={92}
                            dataAnsId={247}
                            dataAnsLabel="Yes"
                        />

                </div>
            </li>
            <li>
                <div className="option_but btn-next ">                  
                    <RadioButtonQuest
                            key="everinc2"
                            className="radio"
                            labelName="No"
                            labelClassName="align-items-center justify-content-center btn-next next5"
                            onClick={optionHandle}
                            value="248"
                            name="everincresedCredLimit"
                            id="everinc2"
                            dataQuestId={92}
                            dataAnsId={248}
                            dataAnsLabel="No"
                        />
                </div>
            </li>
        </ul>
        <div className="mt-2 mb-5 text-center">
            <div>
                {" "}
                <button className="back-btn mainbckBtn bi bi-chevron-left" onClick={backClick} type="button" name="openingCreditLimit" id="back44">
                 Back
                </button>
            </div>
        </div>
    </div>

        
    )
}

export default EverIncreCredLimit;