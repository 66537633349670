import React, { useState, useEffect, useCallback, useRef } from "react";
import AddressTextField from "../../../UI/AddressTextField";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import Button from "../../../UI/Button";
import btnLoader from "../../../../assets/UFC_DC_V1/img/buttonLoader.gif";
import { useFormContext } from "react-hook-form";
import { usePostCodeAddrsChkboard } from "../../../../Hooks/usePostCodeAddrsChkboard";

const PrevPostCode3 = ({
  showPostCode,
  showAddress,
  addClick,
  disablests,
  hideClick,
}) => {
  const { register, trigger, errors, watch, setValue, setError, clearErrors } =
    useFormContext();

  const { getPostcodeAddress, getSplitPostcodeAddress, loading } =
    usePostCodeAddrsChkboard();
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [postcode, setPostcode] = useState(null);
  const scrollToBottom = useRef(null);

  const uuid = localStorage.getItem("uuid") ?? "";

  const searchAddress = useCallback(
    async (e) => {
      const isValid = await trigger("txtPrevPostcode3");

      if (isValid) {
        const postCode = e.target.value;
        setPostcode(postCode);

        await getPostcodeAddress(postCode)
          .then((res) => {
            if (res.data.status === "0") {
              return setError("txtPrevPostcode3", {
                type: "manual",
                message: "Please Enter Valid Postcode",
              });
            }
            if (res.data.length > 0) {
              clearErrors("txtPrevPostcode3");
            }

            setAddressList(res?.data ?? []);
          })
          .catch(function () {
            setError("txtPrevPostcode3", {
              type: "manual",
              message: "Please Enter Valid Postcode",
            });

            setAddressList([]);
          });
      }
    },
    [
      getPostcodeAddress,
      trigger,
      watch,
      uuid,
      setError,
      clearErrors,
      setAddressList,
    ]
  );

  useEffect(() => {
    setAddressList([]);
    setSelectedAddress(null);
  }, [postcode]);

  const fetchAddressDetails = useCallback(
    async (e) => {
      const isValid = await trigger("txtPrevAddress1C");

      if (isValid) {
        const address = e.target.value;

        const response = await getSplitPostcodeAddress(address);
        const data = response?.data;

        setSelectedAddress(data);

        setValue("txtPrevAddressLine1C", data?.line_1);
        setValue("txtPrevAddressLine2C", data?.line_2);
        setValue("txtPrevTown3", data?.town);
        setValue("txtPrevCounty3", data?.county);
        setValue("txtPrevCountry3", data?.country);
        setValue("txtPrevDistrict3", data?.district);
      }
    },
    [getSplitPostcodeAddress, setValue, trigger, watch]
  );

  useEffect(() => {
    setTimeout(() => {
      if (!!selectedAddress && !!scrollToBottom) {
        scrollToBottom.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }, 200);
  }, [selectedAddress]);

  const removeAddress = () => {
    setValue("txtPrevPostcode3", "");
    setValue("txtPrevAddress1C", "");
    setValue("txtPrevAddressLine1C", "");
    setValue("txtPrevAddressLine2C", "");
    setValue("txtPrevCounty3", "");
    setValue("txtPrevTown3", "");
    setValue("txtPrevCountry3", "");
    setAddressList([]);
    hideClick("ThirdAdd");
  };

  return (
    <div className={`postcodeyes row mt-3 ${showAddress}`}>
      <div className="row mb-3 ps-0 pe-0" style={{ marginLeft: "1px" }}>
        <div className="col-12 col-lg-6">
          <AddressTextField
            type="text"
            placeholder="Postcode"
            name="txtPrevPostcode3"
            id="txtPrevPostcode3"
            className="form-control"
            dataId="txtPrevPostcode3"
            autoComplete="off"
            validation={register({
              required: "Please Enter Postcode",
              pattern: {
                value: /([A-Za-z0-9\s]*)$/,
                message: "Please Enter a Valid Postcode",
              },
              minLength: {
                value: 5,
                message: "Please Enter a Valid Postcode",
              },
            })}
            validationMsg={errors["txtPrevPostcode3"]?.message}
            onBlur={searchAddress}
            // onChange={searchAddress}
          />
        </div>

        <div className="col-12 col-lg-6">
          {!loading && (
            <Button
              type="button"
              className="btn-lookup"
              value="Look Up Address"
              buttonText="Look Up Address"
              onClick={searchAddress}
            />
          )}

          {!!loading && (
            <Button
              type="button"
              className="btn-lookup"
              value="Look Up Address"
              buttonText={<img src={btnLoader} alt="loading..." />}
              disabled={!!loading}
            />
          )}
        </div>
      </div>
      <div
        id="currentAddressCollapse"
        className={`col-lg-12 mt-3 ${!!addressList.length ? "" : "d-none"}`}
      >
        {
          <div className={`${!!addressList.length ? "d-block" : "d-none"}`}>
            <AddressSelectBox
              className="form-select mb-lg-3"
              OptionValue={addressList.map((address) => ({
                address: address.address,
                id: address.id,
              }))}
              name="txtPrevAddress1C"
              id="txtPrevAddress1C"
              onChange={(e) => {
                trigger("txtPrevAddress1C");
                fetchAddressDetails(e);
              }}
              myRef={register({
                required: "Please select address",
              })}
              validationMsg={errors["txtPrevAddress1C"]?.message}
            />
          </div>
        }

        {!!addressList.length && (
          <div className={`slide8_add row ${selectedAddress ? "" : "d-none"}`}>
            {selectedAddress?.line_1 != "" && (
              <div
                className={`col-lg-12 mb-3 form-group required-field adj_width`}
              >
                <AddressTextField
                  type="text"
                  placeholder="Address Line 1 *"
                  name="txtPrevAddressLine1C"
                  id="txtPrevAddressLine1C"
                  className="form-control"
                  dataId="txtPrevAddressLine1C"
                  autoComplete="off"
                  readonly={true}
                  validation={register({
                    required: "Please Enter Address Line 1",
                  })}
                  validationMsg={errors["txtPrevAddressLine1C"]?.message}
                />
              </div>
            )}
            {selectedAddress?.line_2 != "" && (
              <div className={`col-lg-12 mb-3 form-group adj_width`}>
                <AddressTextField
                  type="text"
                  placeholder="Address Line 2"
                  name="txtPrevAddressLine2C"
                  id="txtPrevAddressLine2C"
                  className="form-control"
                  dataId="txtPrevAddressLine2C"
                  autoComplete="off"
                  readonly={true}
                  validation={register()}
                />
              </div>
            )}
            {selectedAddress?.county != "" && (
              <div className={`col-lg-12 mb-3 form-group adj_width`}>
                <AddressTextField
                  type="text"
                  placeholder="County"
                  name="txtPrevCounty3"
                  id="txtPrevCounty3"
                  className="form-control"
                  dataId="txtPrevCounty3"
                  autoComplete="off"
                  readonly={true}
                  validation={register()}
                  validationMsg=""
                />
              </div>
            )}
            {selectedAddress?.town != "" && (
              <div className={`col-lg-12 mb-3 form-group adj_width`}>
                <AddressTextField
                  type="text"
                  placeholder="Town *"
                  name="txtPrevTown3"
                  id="txtPrevTown3"
                  className="form-control  hero-input"
                  dataId="txtPrevTown3"
                  autoComplete="off"
                  readonly={true}
                  validation={register({ required: "Please Enter Town" })}
                  validationMsg={errors["txtPrevTown3"]?.message}
                />
              </div>
            )}
            {selectedAddress?.district != "" && (
              <div className={`col-lg-12 mb-3 form-group adj_width`}>
                <AddressTextField
                  type="text"
                  placeholder="District"
                  name="txtPrevDistrict3"
                  id="txtPrevDistrict3"
                  className="form-control"
                  dataId="txtPrevDistrict3"
                  autoComplete="off"
                  readonly={true}
                  validation={register()}
                  validationMsg={errors["txtPrevDistrict3"]?.message}
                />
              </div>
            )}
            {selectedAddress?.country != "" && (
              <div
                className={`col-lg-12 mb-3 form-group adj_width}`}
                ref={scrollToBottom}
              >
                <AddressTextField
                  type="text"
                  placeholder="Country *"
                  name="txtPrevCountry3"
                  id="txtPrevCountry3"
                  className="form-control  hero-input"
                  dataId="txtPrevCountry3"
                  autoComplete="off"
                  readonly={true}
                  validation={register({
                    required: "Please Enter Country",
                  })}
                  validationMsg={errors["txtPrevCountry3"]?.message}
                />
                <div className="text-start">
                  <a
                    className="add-btn text-decoration-underline"
                    onClick={() => {
                      removeAddress();
                    }}
                  >
                    - Remove Address
                  </a>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PrevPostCode3;
