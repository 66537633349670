import React,{ useState } from 'react';
import logo_white from "../../../../assets/UFC_DC_V1/img/logo-white.png";
import PrivacyPolicy from '../Common/PrivacyPolicy';
import Complaints from '../Common/Complaints';
import TC from "../../../../assets/pdf/TOBA_New.pdf";

const Footer = () => {
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showComplaint, setShowComplaint] = useState(false);
  const [showterms, setShowterms] = useState(false);
  
  const modalClick = (clickValue) => {
    switch (clickValue) {
      case "showPrivacy": {
        setShowPrivacy(true);
        break;
      }
      case "exitPrivacy": {
        setShowPrivacy(false);
        break;
      }
      case "showTerms": {
        setShowterms(true);
        break;
      }
      case "exitTerms": {
        setShowterms(false);
        break;
      }
      case "showComplaint": {
        setShowComplaint(true);
        break;
      }
      case "exitComplaint": {
        setShowComplaint(false);
        break;
      }
      default: {
        console.log("No such case exist");
        break;
      }
    }
  }
  return (
    <footer className="py-lg-5 py-md-5 py-sm-4 py-4">
  <div className="container">
    <div className="row">
      <div className="col-lg-3 tex-lg-start mb-3 text-lg-start text-md-start text-sm-center text-center">
        <div className="ft-logo"> <img src={logo_white} height={70} alt /></div>
      </div>
      <div className="col-lg-9">
        <p> TMS Legal Ltd is a limited company registered in England and Wales under company no: 10315962 Its
          registered office is Arena, 9 Nimrod Way, Ferndown, BH21 7UH It is authorised and regulated by the
          Solicitors Regulation Authority, Number: 645041</p>

          <PrivacyPolicy showPrivacy={showPrivacy} modalClick={modalClick} />

          <Complaints showComplaint={showComplaint} modalClick={modalClick} />
        <ul className="m-0 p-0  ">
          <li>
            <a  
            onClick={() => modalClick("showPrivacy")}>
              Privacy and Cookie Policy
            </a>
          </li>
          <li>
            <a href={`${TC}`} target="_blank">
              TMS Legal Terms and Conditions
            </a>
          </li>
          <li>
            <a  onClick={() => modalClick("showComplaint")}>
               Complaints Procedure
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>

  )
}

export default Footer;