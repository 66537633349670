import React from 'react'
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import GtmDataLayer from '../../../../Utility/GtmDataLayer';

const ExceedCreditLimit = ({
    everIncreased,
    onSelectAnswer,
    backClick,
    setExceedCredit,
clsName }) => {
    const optionHandle = async (e) => {
        GtmDataLayer({
            question: "exceed overdraft",
        });

        if (e.target.value === "251") {
            setExceedCredit("251");
            onSelectAnswer(e);
        } else if (e.target.value === "252") {
            setExceedCredit("252");
            onSelectAnswer(e);
        }

    }
    let toggle = "";
    if (everIncreased == "248") {
        toggle = "everincresedCredLimit";
    } else {
        toggle = "highestcreditLimit";
    }
    return (
        <div className={`qsSlider slidea8 ${clsName}`}>
            <h2 className="qs-title slide-up-now">
                Did you ever exceed your overdraft limit?
            </h2>
            <ul className="btn-option optinal-items  two-options  slide-up-now  ">
                <li>
                    <div className=" option_but ">
                        <RadioButtonQuest
                            key="exov1"
                            className="radio"
                            labelName="Yes"
                            labelClassName="align-items-center justify-content-center next6"
                            onClick={optionHandle}
                            value="251"
                            name="exceedCredLimit"
                            id="exov1"
                            dataQuestId={95}
                            dataAnsId={251}
                            dataAnsLabel="Yes"
                        />
                    </div>
                </li>
                <li>
                    <div className="option_but btn-next">
                        <RadioButtonQuest
                            key="exov2"
                            className="radio"
                            labelName="No"
                            labelClassName="align-items-center justify-content-center next6"
                            onClick={optionHandle}
                            value="252"
                            name="exceedCredLimit"
                            id="exov2"
                            dataQuestId={95}
                            dataAnsId={252}
                            dataAnsLabel="No"
                        />
                    </div>
                </li>
            </ul>
            <div className="mt-2 mb-5 text-center">
                <div>
                    {" "}
                    <button className="back-btn mainbckBtn bi bi-chevron-left" onClick={backClick} name={toggle} type="button" id="back7">
                        Back
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ExceedCreditLimit;