import React, { useState, useRef, useContext } from "react";
import EditInfo from "../../../../assets/EditDetails/img/editinfo.png";
import SelectBox from "../../../UI/SelectBox";
import { SalutationVAQUB4 } from "../../../../Constants/Questions";
import Input from "./Input";
import DateOfBirth from "./DateOfBirth";
import Telephone from "../../../UI/Telephone";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import Email from "../../../UI/Email";
import PostCode from "./PostCode";

const PersonalDetails = ({
  editPersonalDetails,
  register,
  clearErrors,
  errors,
  getValues,
  trigger,
  setError,
  setF_name,
  setEditPersonalDetails,
  setHaveotherAddress,
  prevFormData,
  setValue,
  formSubmit,
}) => {
  const [dobday, setDobday] = useState(prevFormData?.DobDay);
  const [dobmonth, setDobmonth] = useState(prevFormData?.DobMonth);
  const [dobyear, setDobyear] = useState(prevFormData?.DobYear);
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const splitForm = useRef(null);
  const [notDobValid, setNotDobValid] = useState(false);
  const [pCodeValid, setPCodeValid] = useState(true);

  const setFname = (e) => {
    let UcFname =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    setF_name(UcFname);
  };
  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values["txtPhone"];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone);
      if (getPhoneValidation.data.status_code === 0) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        document.getElementById("txtPhone").focus();
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        document.getElementById("txtPhone").focus();
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        document.getElementById("txtPhone").focus();
        return 0;
      } else {
        return 1;
      }
    }
  };
  const emailValidCheck = async () => {
    const values = getValues();
    const email = values["txtEmail"];
    if (email !== "") {
      const getEmailValidation = await emailValidation(email);
      if (getEmailValidation.data.status_code === 1) {
        return 1;
      } else {
        setError("txtEmail", {
          type: "manual",
          message: getEmailValidation.data.message,
        });
      }
    }
  };
  const validateDateOfBirth = (dobDay, dobMonth, dobYear) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const isValidMonth = monthNames.includes(dobMonth);
    const isValidYear = dobYear >= 1910;

    if (
      isValidMonth &&
      isValidYear &&
      isValidDayInMonth(dobDay, monthNames.indexOf(dobMonth) + 1, dobYear)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const isValidDayInMonth = (day, month, year) => {
    const daysInMonth = [
      31,
      isLeapYear(year) ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ];
    return day >= 1 && day <= daysInMonth[month - 1];
  };

  const basicDetailsValidation = async (e) => {
    if (prevFormData?.address1) {
      setValue("address1", prevFormData?.address1);
    }

    let errorFlag = 0;
    var txtFNameResult;
    var txtLNameResult;
    var lstDobDayResult;
    var lstDobMonthResult;
    var lstDobYearResult;
    var lstPostResult;
    var address1Result;
    var txtPhoneResult;
    var txtEmailResult;
    var lstSalutationResult = await trigger("lstSalutation");

    // Name Validation
    if (lstSalutationResult) {
      txtFNameResult = await trigger("txtFName");
    } else {
      document.getElementById("lstSalutation").focus();

      return false;
    }
    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      document.getElementById("txtFName").focus();
      return false;
    }

    // DOB validation
    if (txtLNameResult) {
      lstDobDayResult = await trigger("DobDay");
    } else {
      document.getElementById("txtLName").focus();
      return false;
    }
    if (lstDobDayResult) {
      lstDobMonthResult = await trigger("DobMonth");
    } else {
      document.getElementById("DobDay").focus();
      return false;
    }
    if (lstDobMonthResult) {
      lstDobYearResult = await trigger("DobYear");
    } else {
      document.getElementById("DobMonth").focus();
      return false;
    }

    // Phone no. & email validation
    if (lstDobYearResult) {
      txtEmailResult = await trigger("txtEmail");
    } else {
      document.getElementById("DobYear").focus();
      return false;
    }

    if (txtEmailResult) {
      txtPhoneResult = await trigger("txtPhone");
    } else {
      document.getElementById("txtEmail").focus();
      return false;
    }

    // Address validation
    if (txtPhoneResult) {
      lstPostResult = await trigger("txtPostCode");
    } else {
      document.getElementById("txtPhone").focus();
      return false;
    }
    if (lstPostResult) {
      address1Result = await trigger("address1");
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }

    const selectElement = document.getElementById("address1");

    const optionCount = selectElement.options.length;

    if ((!address1Result && optionCount > 0) || !pCodeValid) {
      document.getElementById("address1").focus();
      return false;
    }

    const dobIsValid = validateDateOfBirth(dobday, dobmonth, dobyear);
    if (!dobIsValid) {
      setNotDobValid(true);
      document.getElementById("DobDay").focus();
      document.getElementById("DobMonth").focus();
      document.getElementById("DobYear").focus();
      return false;
    } else {
      setNotDobValid(false);
    }

    if (
      !lstSalutationResult ||
      !txtFNameResult ||
      !txtLNameResult ||
      !lstDobDayResult ||
      !lstDobMonthResult ||
      !lstDobYearResult ||
      !lstPostResult ||
      !txtPhoneResult ||
      !txtEmailResult ||
      (!address1Result && optionCount > 0) ||
      !pCodeValid ||
      !dobIsValid
    ) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      return true;
    } else {
      return false;
    }
  };

  const nextSlide = async () => {
    const validcheck = await basicDetailsValidation();

    const phoneValidity = await phoneValidCheck();
    if (validcheck && phoneValidity) {
      formSubmit();
      setEditPersonalDetails("hide");
      setHaveotherAddress("show");
    } else {
      return false;
    }
  };

  return (
    <div>
      <div className={`slide2 ${editPersonalDetails}`}>
        {/* <form
          ref={splitForm}
          name="split_form"
          id="user_form"
          method="POST"
          autoComplete="off"
        > */}
        <div className="form-header d-flex align-items-center">
          <div className="col-lg-2 col-3">
            <img src={EditInfo} alt="" />
          </div>
          <div className="col-lg-10 col-9 bor-l  align-self-center">
            <h4>
              Edit your <br className="d-block d-md-none" />
              <span>personal details</span>
            </h4>
            <p>Edit your details here if they are incorrect</p>
          </div>
        </div>
        <div className="col-12">
          <div className="row  ps-0 pe-0">
            <p>
              <strong>Edit your Name</strong>
            </p>
            <div className="col-lg-6 mb-2">
              <SelectBox
                className="form-select select1"
                OptionValue={SalutationVAQUB4}
                name="lstSalutation"
                id="lstSalutation"
                defaultValue={prevFormData?.lstSalutation}
                onChange={(e) => {
                  clearErrors("lstSalutation");
                }}
                clearErrors={clearErrors}
                myRef={register({ required: "Please select your title" })}
                validationMsg={
                  errors.lstSalutation && errors.lstSalutation.message
                }
              />
            </div>
            <div className="col-lg-6 mb-2">
              <Input
                type="text"
                placeholder="First Name"
                className="form-control"
                name="txtFName"
                id="txtFName"
                defaultValue={prevFormData?.txtFName}
                rules={{
                  required: "Please enter your first name",
                  minLength: {
                    value: 3,
                    message: "Please enter valid first name",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Your name is invalid. Please recheck",
                  },
                }}
                transform={(value) =>
                  value.replace(/^[ ]+/g, "").replace(/[^a-zA-Z\s]/g, "")
                }
                onChange={(e) => {
                  clearErrors("txtFName");
                  setFname(e);
                }}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <Input
                type="text"
                placeholder="Last Name"
                className="form-control"
                name="txtLName"
                id="txtLName"
                defaultValue={prevFormData?.txtLName}
                rules={{
                  required: "Please enter your last name",
                  minLength: {
                    value: 3,
                    message: "Please enter valid last name",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Your name is invalid. Please recheck",
                  },
                }}
                transform={(value) =>
                  value.replace(/^[ ]+/g, "").replace(/[^a-zA-Z\s]/g, "")
                }
              />
            </div>
          </div>
          <hr />
          <div className="row  ps-0 pe-0">
            <p>
              <strong>Edit your Date of Birth</strong>
            </p>
            <DateOfBirth
              setDobday={setDobday}
              setDobmonth={setDobmonth}
              setDobyear={setDobyear}
              notDobValid={notDobValid}
              register={register}
              errors={errors}
              prevFormData={prevFormData}
            />
          </div>
          <hr />
          <div className="col-lg-12 mb-3  ">
            <p>
              <strong>Edit your Email Address</strong>
            </p>

            <Email
              name="txtEmail"
              className="form-control mb-0 privacyg pr-guard"
              id="txtEmail"
              placeholder="Enter Email Address"
              value={prevFormData?.txtEmail}
              onBlur={emailValidCheck}
              validation={register({
                required: "Please enter valid email address",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              })}
            />
            {errors.txtEmail && (
              <span className="error_msg " id="email_err">
                {errors.txtEmail.message}
              </span>
            )}
          </div>
          <hr />
          <div className="col-lg-12 mb-3  ">
            <p>
              <strong>Edit your Mobile Number</strong>
            </p>
            <Telephone
              name="txtPhone"
              id="txtPhone"
              className="form-control privacyg mb-0 pr-guard"
              placeholder="Enter Mobile Number"
              value={prevFormData?.txtPhone}
              onBlur={phoneValidCheck}
              onKeyPress={(e) => !/[0-9]+$/.test(e.key) && e.preventDefault()}
              maxlength="11"
              validation={register({
                pattern: {
                  value: /^[0-9]*$/i,
                  message: "Please enter a valid phone number",
                },
                required: "Please enter a valid phone number",
              })}
            />
            {errors.txtPhone && (
              <span className="error_msg " id="email_err">
                {errors.txtPhone.message}
              </span>
            )}
          </div>
          <hr />
          <PostCode formData={prevFormData} setPCodeValid={setPCodeValid} />
          <hr />
        </div>
        <div className="col-12 row justify-content-end">
          <div className="col-12 col-lg-8 pr-0">
            <button
              type="button"
              className="btn-banner btn-fullwidth mt-4 next-slide3"
              onClick={nextSlide}
            >
              Next &gt;
            </button>
          </div>
        </div>
        {/* </form> */}
      </div>
    </div>
  );
};

export default PersonalDetails;
