import React from "react";
import logoWhite from "../../../../assets/UFC_DC_V1/img/logo-white.png";

const Header = () => {
  return (
    <>
      <div>
        <div className="brand d-lg-block d-md-block d-sm-none d-none">
          <div className="logo pt-3">
              {" "}
              <img src={logoWhite} height="60" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
