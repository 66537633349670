// import { useState } from "react";
import React, {useContext, useState} from "react";
import { Api,CommonEndApi } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";
import {useErrorWebhook} from "./useErrorWebhook";
import VisitorParamsContext from "../Contexts/VisitorParams";


export const usePostCodeAddress = () => {

  const { ErrorWebhookTrigger } = useErrorWebhook();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const uuid=visitorParameters.visitor_parameters.uuid;
  const [loading, setLoading] = useState(false);


  const getPostcodeAddress = async (postcode) => {
    setLoading(true);
    const response = await CommonEndApi.get(
      "api/get-addr-list?ProjectCode=" + EnvConstants.AppAdtopiaUPID + '&Environment=' + EnvConstants.AppEnv + '&postcode='+postcode,
      {}
    ).catch(function (error) {
      const erroMessage = {
        serviceName : 'GET POSTCODE ADDRESS LIST',
        errorReason:error.message,
        errorCode:error.code,
        uuid:uuid

      }
      ErrorWebhookTrigger(erroMessage);
    });
    setLoading(false);
    return response;
  };
  const getSplitPostcodeAddress = async (AddressID, postcode) => {
    setLoading(true);
    const response = await CommonEndApi.get(
      "api/get-addr-details?ProjectCode=" + EnvConstants.AppAdtopiaUPID + '&Environment=' + EnvConstants.AppEnv + '&addressId='+AddressID,
      {}
    ).catch(function (error) {
      const erroMessage = {
        serviceName : 'GET POSTCODE ADDRESS DETAILS',
        errorReason:error.message,
        errorCode:error.code,
        uuid:uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    setLoading(false);
    return response;
  };
  return { getPostcodeAddress, getSplitPostcodeAddress,loading };
};
