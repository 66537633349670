import React from 'react'
import Logo from "../../../../assets/EditDetails/img/logo.png";
import SSL from "../../../../assets/EditDetails/img/ssl-new.png";

const Header = () => {
    return (
        <div>
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-5">
                            <a href="index.html">
                                <img className="header-logo pt-1" src={Logo} height="60px" />
                            </a>
                        </div>
                        <div className="col-lg-6 col-7 justify-content-end d-flex  align-items-center"> <img className="header-logo-3" src={SSL} height={50} alt="" /></div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Header