import React from "react";
import RadioButtonQuest from '../../../UI/RadioButtonQuest';
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const SufferedFinancialHardship = ({ onSelectAnswer, backClick, clsName, exceedCredit }) => {


    const optionHandle = async (e) => {

        GtmDataLayer({
            question: "financial hardship",
        });
        
        if (e.target.value === "266") {
            onSelectAnswer(e);
        } else if (e.target.value === "265") {
            onSelectAnswer(e);
        }
    }

    let toggle = "";
    if (exceedCredit == "252") {
        toggle = "exceedCredLimit";
    } else {
        toggle = "overlimit";
    }


    return (
        <>
            <div className={`qsSlider slidea11 ${clsName}`}>
                <h2 className="qs-title slide-up-now">
                    {" "}
                    I have suffered financial hardship due to interest and charges
                    being applied to my account - do you agree?
                </h2>
                <div className="btn-option optinal-items  two-options  slide-up-now">
                    <div className=" option_but mb-3">
                        <RadioButtonQuest
                            key="hardship1"
                            className="radio"
                            labelName="Agree"
                            labelClassName="align-items-center justify-content-center next11"
                            onClick={optionHandle}
                            value="265"
                            name="financialhardship"
                            id="hardship1"
                            dataQuestId={98}
                            dataAnsId={265}
                            dataAnsLabel="Agree"
                        />
                    </div>
                    <div className=" option_but  mb-3">
                        <RadioButtonQuest
                            key="hardship2"
                            className="radio"
                            labelName="Disagree"
                            labelClassName="align-items-center justify-content-center next11"
                            onClick={optionHandle}
                            value="266"
                            name="financialhardship"
                            id="hardship2"
                            dataQuestId={98}
                            dataAnsId={266}
                            dataAnsLabel="Disagree"
                        />
                    </div>
                </div>
                <div className=" mb-4 text-center">
                    <div>
                        {" "}
                        <button
                            type="button"
                            name={toggle}
                            className="back-btn mainbckBtn bi bi-chevron-left"
                            id="back10"
                            onClick={backClick}>
                            Back
                        </button>
                    </div>
                </div>

            </div>
        </>
    );
};

export default SufferedFinancialHardship;
