import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import GtmDataLayer from '../../../../Utility/GtmDataLayer';

const BankActedIrresponsibly = ({
    onSelectAnswer,
    backClick,
    unQualifiedAnswer,
    clsName }) => {
    const [claimModal, setshowClaimModal] = useState(false);
    const handleCloseclaimModal = () => setshowClaimModal(false);
    const optionHandle = async (e) => {
        GtmDataLayer({
            question: "bank irresponsibly",
        });
        if (e.target.value === "250") {
            // unQualifiedAnswer(e);
            setshowClaimModal(true);
        } else if (e.target.value === "249") {
            onSelectAnswer(e);
        }

    }
    return (
        <>
            <div className={`qsSlider slidea5 ${clsName}`}>
                <h2 className="qs-title slide-up-now">
                    The bank acted irresponsibly by providing me with an overdraft / increasing my overdraft limit without conducting a proper affordability assessment – do you agree?
                </h2>
                <ul className="btn-option optinal-items  two-options   slide-up-now">
                    <li>
                        <div className="option_but ">
                            <RadioButtonQuest
                                key="bankRes1"
                                className="radio"
                                labelName="Agree"
                                labelClassName="align-items-center justify-content-center next6"
                                onClick={optionHandle}
                                value="249"
                                name="bankActedIrresponsibly"
                                id="bankRes1"
                                dataQuestId={93}
                                dataAnsId={249}
                                dataAnsLabel="Agree"
                            />

                        </div>
                    </li>
                    <li>
                        <div className="option_but btn-next">
                            <RadioButtonQuest
                                key="bankRes2"
                                className="radio"
                                labelName="Disagree"
                                labelClassName="align-items-center justify-content-center next6"
                                onClick={optionHandle}
                                value="250"
                                name="bankActedIrresponsibly"
                                id="bankRes2"
                                dataQuestId={93}
                                dataAnsId={250}
                                dataAnsLabel="Disagree"
                            />
                        </div>
                    </li>
                </ul>
                <div className="mt-2 mb-5 text-center">
                    <div>
                        {" "}

                        <button className="back-btn mainbckBtn bi bi-chevron-left" onClick={backClick} type="button" name="everincresedCredLimit" id="back45">
                            Back
                        </button>{" "}
                    </div>
                </div>
            </div>

            <Modal
                show={claimModal}
                onHide={handleCloseclaimModal}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="creditpopup">
                    <div className="pb-3">
                        <div className="row align-items-center">
                            <div className="col-lg-12 col-md-12 col-sm-3 col-2 text-center">
                                <div className="mb-lg-3 mb-md-3 mb-sm-0 mb-0">
                                    <img
                                        className="alertimg"
                                        src="../../assets/QuestionaireUFC_DC_V1/img/alert.png"
                                        height="50"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-9 col-10">
                                <h6 className="mb-0 text-lg-center text-md-center text-sm-start text-start px-lg-3 px-md-3 px-sm-0 px-0">
                                    You don't qualify
                                </h6>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={closeModal}>
            Close
          </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default BankActedIrresponsibly;