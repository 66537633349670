import React, { useState } from "react";
import TextArea from "../../../UI/TextArea";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const HowStuckAffects = ({ 
    validation,
     backClick, 
     saveInputAns,
     getValues,clsName }) => {
    const [error, setError] = useState("");

    const handleClick = async (e) => {
  
        const values = getValues();
        const reason = values['stuckAffects'].trim();
        if (reason == "" || reason.length < 3) {
            setError("Please enter");
        } else {
            setError("");
            GtmDataLayer({
                question: "overdraft effect",
            });
            saveInputAns(e);
        }
    };
    
    return (
        <>
          

            <div className={`qsSlider slidea5 ${clsName}`}>
            <h2 className="qs-title slide-up-now">
                {" "}
                You've told us you were stuck in your overdraft as a result you didn't see a frequent positive balance- how did this affect you?</h2>
                <div className="row">
            {/* <ul className="btn-option optinal-items  two-options  slide-up-now">
                <li> */}
                    <div className=" option_but ">
                    <TextArea
                            className="form-control"
                            row={4}
                            name="stuckAffects"
                            placeholder="Type here"
                            validation={validation({
                                required : true
                            })}
                        />
                        {error && (
                            <span className="error_msg">{error}</span>
                        )}

                    </div>
                {/* </li> */}
                <div className="col-lg-12 mb-2 mt-2 d-flex justify-content-center align-items-center">
                    <button className="btn-comm2    w-100  next7" type="button"  name="howstuckaffects" onClick={handleClick}                             data-quest-id={106} >
                        {/* <span className="btntext"> Continue </span> */}
                        Continue
                    </button>
                </div>
            {/* </ul> */}
            </div>
            <p className="mt-1 mb-1">(Examples: Anxiety, Stress, Depression, Physical Ailments, Mental Health)</p>

               
            <div className="mt-2 mb-5 text-center">
                <div>
                    {" "}
                    <button className="back-btn mainbckBtn bi bi-chevron-left" id="back6" name='stuckinoverdraft' type='button' onClick={backClick}>
                       Back
                    </button>
                </div>
            </div>
        </div>
        </>
    );
};

export default HowStuckAffects;
