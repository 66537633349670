import { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `/pdf/pdf.worker.min.js`;

const Decl = (props) => {

  const [decl, setDecl] = useState('hide');
  const [numPages, setNumPages] = useState(null);
  const [pageWidth, setPageWidth] = useState(window.innerWidth * 0.9);

  const closeDecl = () => setDecl('hide');
  const showDecl = () => setDecl('show');

  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const updatePageWidth = () => {
    const modalMaxWidth = 800; // Set a max width for the modal
    setPageWidth(Math.min(window.innerWidth * 0.9, modalMaxWidth)); // 90% of the viewport or modal max width
  };

  useEffect(() => {
    updatePageWidth(); // Update on component mount
    window.addEventListener('resize', updatePageWidth); // Update on window resize
    return () => window.removeEventListener('resize', updatePageWidth);
  }, []);


  return (
    <>
      <div className='PrivacyCustom text-start'>
        <a
          className="btn btn-link fnt-sml"
          onClick={showDecl}
        >
          {/* <img src="/pdf.png" height={30} /> */}
           Declaration Form
        </a>

        <div
          className={`modal fade blackover ${decl}`}
          id="decl"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg over_craft">
            <div className="modal-content">
              <div className="modal-body">
                <div className="modal-header  border border-0 pb-0">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeDecl} style={{ backgroundColor: 'red' }}></button>
                </div>
                <div className="container-sm my-4 Cpa_page_style d-flex align-items-center justify-content-center">
                <Document
                  file="/pdf/Overdraft_Declaration.pdf"
                  onLoadSuccess={onLoadSuccess}
                  onLoadError={(error) => console.error("Error loading PDF:", error)}
                >
                  {/* Loop through each page */}
                  {Array.from(new Array(numPages), (_, index) => (
                    <Page 
                      key={index} 
                      pageNumber={index + 1} 
                      width={pageWidth} 
                    />
                  ))}
                </Document>
              </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  );
};

export default Decl;
