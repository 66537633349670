import React, { useRef, useState } from "react";
import CheckBox from "../../../UI/CheckBox";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
const WereAlreadyPaying = ({
  clsName,
  reff,
  onSelectAnswer,
  validation,
  validationMsg,
  getValues,
  trigger,
  setError,
  checklistOptions,
  questArr,
  backClick,
  setChecklistOptions,
}) => {
  const [showError, setshowError] = useState("hide");
  const [btndisabled, setBtndisabled] = useState(false);
  const checkboxRefs = useRef({});

  const labelclicked = async (e) => {
    let date_index = e.target.id;
    let selectedCount = checklistOptions.length;
    if (selectedCount == 0) {
      setBtndisabled(false);
      setshowError("show");
    } else {
      GtmDataLayer({
        question: "paying monthly",
      });
      setBtndisabled(true);
      setChecklistOptions(checklistOptions);
      onSelectAnswer(e);
    }
  };

  const onSelectOption = (e) => {
    if (e.target.checked === true) {
      setshowError("hide");
      checklistOptions.push({ id: +e.target.value });
      // checkBoxDisable(e.target.value);
    } else {
      let deselect = e.target.value;
      const decre = checklistOptions.map((OptionArray, index) => {
        if (deselect == OptionArray.id) {
          checklistOptions.splice(index, 1);
          // if (checklistOptions.length == 0) {
          //   checkBoxEnable();
          // }
        }
      });
    }
  };

  const handleChange = (e) => {
    // const re = /^[0-9\b]+$/;
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    e.target.value = e.target.value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  let checkArray = questArr.answers;

  const questionId = questArr.questionId ? questArr.questionId : 0;

  const lenderCheckBox = checkArray.map((checArr, index) => {
    let heightcls = checArr.value == "104" ? "heightnewcls" : "";
    return (
      <div className={`btn-option2 optinal-items optinal-items-payment   slide-up-now ${heightcls}`} key={checArr.value}>
        <div
          className="option_but mb-3"
          ref={(e) => (checkboxRefs.current[checArr.value] = e)}
        >
          <div className="odnewscss">
            <CheckBox
              dataId={`wereAlreadyPaying ${checArr.value}`}
              name="chkBankName[]"
              onChange={onSelectOption}
              labelClassName="align-items-center tickicon tick-check-box"
              CheckBoxText={'<span></span>' + checArr.label}
              value={checArr.value}
            />
          </div>
        </div>
      </div>
    );
  });



  const checkBoxEnable = () => {
    checkArray.map((checArr, index) => {
      checkboxRefs.current[checArr.value].childNodes[0].disabled = false;
      checkboxRefs.current[checArr.value].childNodes[1].classList.remove(
        "disabledcheckbox"
      );
    });
  };

  return (
    <>
      <div className={`${clsName} qsSlider slidea15`}>
        <h2 className="qs-title slide-up-now">
          {" "}
          When you took out the overdraft, were you already paying monthly
          for one or more of the following: <br/>
        </h2>
        <p className="lbl-fnt"> 
            <i>(Select all that applies)</i>
        </p>
        {lenderCheckBox}

        <div className="row mb-2 ">
          <span className={`error_msg ${showError}`} id="email_err">
            Please select at least one
          </span>
          <div className="col-12 ">
            <button
              className="btn-comm2   w-100  "
              id="next15"
              name="wereAlreadyPaying"
              type="button"
              data-quest-id={79}
              onClick={labelclicked}
            >
              Continue
              {/* <span className="btntext"> Continue </span> */}
            </button>
          </div>
        </div>

        <div className="mt-2 mb-5 text-center">
          <button
            name="dependents"
            className="back-btn mainbckBtn bi bi-chevron-left"
            id="back14"
            value=""
            type="button"
            onClick={backClick}
          >
            Back
          </button>
        </div>
      </div>
    </>
  );
};
export default WereAlreadyPaying;
