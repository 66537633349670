import React from "react";
import RadioButtonQuest from '../../../UI/RadioButtonQuest';
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const RecieveAnyIncome = ({ onSelectAnswer, backClick, clsName, setRecieveIncomeback }) => {


    const optionHandle = async (e) => {

        GtmDataLayer({
            question: "recieve income",
        });

        if (e.target.value === "267") {
            setRecieveIncomeback("267");
            onSelectAnswer(e);
        } else if (e.target.value === "268") {
            setRecieveIncomeback("268");
            onSelectAnswer(e);
        }

    }


    return (
        <>
            <div className={`qsSlider slidea12 ${clsName}`}>
                <h2 className="qs-title slide-up-now">
                {" "}
                                                In the year when you took the overdraft did you receive any
                                                income?
                </h2>
                <div className="btn-option optinal-items  two-options  slide-up-now">
                    <div className=" option_but mb-3">
                        <RadioButtonQuest
                            key="recieve1"
                            className="radio"
                            labelName="Yes"
                            labelClassName="align-items-center justify-content-center next12"
                            onClick={optionHandle}
                            value="267"
                            name="recieveanyincome"
                            id="recieve1"
                            dataQuestId={100}
                            dataAnsId={267}
                            dataAnsLabel="Yes"
                        />
                    </div>
                    <div className=" option_but  mb-3">
                        <RadioButtonQuest
                            key="recieve2"
                            className="radio"
                            labelName="No"
                            labelClassName="align-items-center justify-content-center next12"
                            onClick={optionHandle}
                           value="268"
                            name="recieveanyincome"
                            id="recieve2"
                            dataQuestId={100}
                            dataAnsId={268}
                            dataAnsLabel="No"
                        />
                    </div>
                </div>
                <div className=" mb-5 text-center">
                    <div>
                        {" "}
                        <button
                            type="button"
                            name="financialhardship"
                            className="back-btn mainbckBtn bi bi-chevron-left"
                            id="back11"
                            onClick={backClick}>
                            Back
                        </button>
                    </div>
                </div>

            </div>
        </>
    );
};

export default RecieveAnyIncome;
