import { useState } from "react";
import { Api } from "../api/Api";
import { useErrorWebhook } from "./useErrorWebhook";

export const useGetCreditAnalysisData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const getCreditAnalysisData = async (
    visitor_parameters,
    data,
    message_type,
    additionalData = {}
  ) => {
    setIsLoading(true);
    const response = await Api.post("api/v1/credit_check", {
      visitor_parameters,
      data,
      message_type,
      ...additionalData,
    }).catch(function (error) {
      const erroMessage = {
        serviceName: `Credit check - ${message_type}`,
        errorReason: error.message,
        errorCode: error.code,
        uuid: visitor_parameters.uuid,
      };
      ErrorWebhookTrigger(erroMessage);
      return {
        data: {
          status: "Success",
          error: true,
        },
      };
    });
    setIsLoading(false);
    return response;
  };
  return { getCreditAnalysisData, isLoading };
};
