import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import CheckBox from "../../../UI/CheckBox";
import CheckboxOutLabel from "../../../UI/CheckboxOutLabel";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const FurtherBorrowing = ({ onSelectAnswer, backClick, setCheckedOptions, checkedOptions, clsName }) => {

    const [questResult, setQuestResult] = useState(false);

    const optionClicked = (e) => {

        if (e.target.checked === true) {
            setQuestResult(false);
            checkedOptions.push({ id: +e.target.value });
        } else {
            let deselect = e.target.value;
            const decre = checkedOptions.map((OptionArray, index) => {
                if (deselect == OptionArray.id) {
                    checkedOptions.splice(index, 1);
                    if (checkedOptions.length == 0) {
                    }
                }
            });
        }
    };

    const selectedClick = (e) => {
        e.preventDefault();

        let selectedCount = checkedOptions.length;
        if (selectedCount == 0) {
            setQuestResult(true);
        } else {
            setCheckedOptions(checkedOptions);
            GtmDataLayer({
                question: "following apply",
            });
            onSelectAnswer(e);
        }
    };

    return (
        <>

            <div className={`qsSlider slidea5 ${clsName}`}>
                <h2 className="qs-title slide-up-now">
                    {" "}
                    While having an overdraft did any of the following apply{" "}<br/>
                </h2>
                <p className="lbl-fnt"> 
            <       i>(Select all that applies)</i>
                </p>
                <div className="btn-option optinal-items optinal-items-payment pb-3  slide-up-now  ">
                    <div className=" option_but mb-3">
                        <div className="odnewscss">
                            <CheckboxOutLabel
                                name="furtherborrowingforfinance"
                                id="optionztwo"
                                labelClassName="tick-check-box tick-check-box"
                                value="314"
                                labelName="Payday lending"
                                onClick={optionClicked}
                                dataQuestId={112}
                                dataAnsId={314}
                                dataAnsLabel="Payday lending"
                            />
                        </div>
                    </div>
                    <div className=" option_but mb-3">
                        <div className="odnewscss">
                            <CheckboxOutLabel
                                name="furtherborrowingforfinance"
                                id="optionztwo2"
                                labelClassName="tick-check-box tick-check-box"
                                value="315"
                                labelName="Loans"
                                onClick={optionClicked}
                                dataQuestId={112}
                                dataAnsId={315}
                                dataAnsLabel="Loans"
                            />
                        </div>
                    </div>
                    <div className=" option_but mb-3">
                        <div className="odnewscss">
                            <CheckboxOutLabel
                                name="furtherborrowingforfinance"
                                id="optionztwo3"
                                labelClassName="tick-check-box tick-check-box"
                                value="316"
                                labelName="Credit cards"
                                onClick={optionClicked}
                                dataQuestId={112}
                                dataAnsId={316}
                                dataAnsLabel="Credit cards"
                            />
                        </div>
                    </div>
                    <div className=" option_but mb-3">
                        <div className="odnewscss">
                            <CheckboxOutLabel
                                name="furtherborrowingforfinance"
                                id="optionztwo4"
                                labelClassName="tick-check-box tick-check-box"
                                value="317"
                                labelName="Borrowing from friends and family"
                                onClick={optionClicked}
                                dataQuestId={112}
                                dataAnsId={317}
                                dataAnsLabel="Borrowing from friends and family"
                            />
                        </div>
                    </div>
                    {questResult && (
                        <span className="error_msg mt-1" id="email_err">
                            Please select at least one
                        </span>
                    )}
                </div>
                <div className="row mb-3 ">
                    <div className="col-12 ">
                        <button className="btn-comm2   w-100  "
                            type="button"
                            id="next22"
                            onClick={selectedClick}
                            data-quest-id={112}
                            name="furtherborrowing">
                            {/* <span className="btntext"> Continue </span> */}
                            Continue
                        </button>
                    </div>
                </div>
                <div className="mt-2 mb-5 text-center">
                    <div>
                        {" "}
                        <button className="back-btn mainbckBtn bi bi-chevron-left" id="back111" name="didtakefurtherborrowing" type="button" onClick={backClick}>
                        Back
                        </button>
                    </div>
                </div>
            </div>

        </>
    );
};

export default FurtherBorrowing;
