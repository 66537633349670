import React from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const HowOftenExceedCredit = ({
    onSelectAnswer,
    backClick,
    clsName
}) => {
    const optionClicked = async (e) => {
        GtmDataLayer({
            question: "overdraft limit",
        });
        onSelectAnswer(e);
    };

    return (
        <>
        <div className={`qsSlider slidea9 ${clsName}`}>
        <h2 className="qs-title slide-up-now">
                    {" "}
                    How often did you exceed your overdraft limit?
                </h2>
                <div className="btn-option optinal-items optinal-items-payment slide-up-now  ">
                    <div className=" option_but mb-3">

                        <RadioButtonQuest
                            key="offex1"
                            className="radio"
                            labelName="Always"
                            labelClassName="align-items-center next9"
                            onClick={optionClicked}
                            value="257"
                            name="oftenexceedCredit"
                            id="offex1"
                            dataQuestId={96}
                            dataAnsId={257}
                            dataAnsLabel="Always"
                        />
                    </div>
                    <div className=" option_but mb-3">

                        <RadioButtonQuest
                            key="offex2"
                            className="radio"
                            labelName="Sometimes"
                            labelClassName="align-items-center next9"
                            onClick={optionClicked}
                            value="258"
                            name="oftenexceedCredit"
                            id="offex2"
                            dataQuestId={96}
                            dataAnsId={258}
                            dataAnsLabel="Sometimes"
                        />
                    </div>
                    <div className=" option_but mb-3">
                        <RadioButtonQuest
                            key="offex3"
                            className="radio"
                            labelName="Rarely"
                            labelClassName="align-items-center next9"
                            onClick={optionClicked}
                            value="259"
                            name="oftenexceedCredit"
                            id="offex3"
                            dataQuestId={96}
                            dataAnsId={259}
                            dataAnsLabel="Rarely"
                        />
                    </div>
                    <div className=" option_but mb-3">
                        <RadioButtonQuest
                            key="offex4"
                            className="radio"
                            labelName="Never"
                            labelClassName="align-items-center next9"
                            onClick={optionClicked}
                            value="260"
                            name="oftenexceedCredit"
                            id="offex4"
                            dataQuestId={96}
                            dataAnsId={260}
                            dataAnsLabel="Never"
                        />
                    </div>
                </div>
                <div className="mt-2 mb-5 text-center">
                    <div>
                        {" "}
                        <button className="back-btn mainbckBtn bi bi-chevron-left" id="back8" name="exceedCredLimit" onClick={backClick} type="button">
                           Back
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default HowOftenExceedCredit; 