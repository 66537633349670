import React from 'react'
import EditInfo from "../../../../assets/EditDetails/img/editinfo.png";
import { convertMonthToNumber } from "../../../../Utility/ConvertMonthToNumber";
import { useClaimReg } from "../../../../Hooks/useClaimReg";

const UserCurrentDetails = ({
    firstQuestion,
    prevFormData,
    setFirstQuestion,
    setEditPersonalDetails,
    SkippedLeads,
    uuid
}) => {

    const fullName = prevFormData.txtFName + " " + prevFormData.txtLName;
    const month = convertMonthToNumber(prevFormData?.DobMonth);
    const year = prevFormData?.DobYear;
    const day = prevFormData?.DobDay;
    const dob = `${month}-${day}-${year}`;
    const { updateClaimReg } = useClaimReg();

    const nextSlide = async (e) => {
        const claimReg = await updateClaimReg(uuid, "UFC_DC_V1", "edit_details_click");
        if(e.target.name ==="edit"){
            setEditPersonalDetails("show");
            setFirstQuestion("hide");
        } else {
            SkippedLeads("skipEditDetails", true);
        }
    };

    return (
        <div>
            <div className={`slide1 ${firstQuestion}`}>
                <div className="form-header mb-2 mt-0 d-flex align-items-center">
                    <div className="col-lg-2 col-3"><img src={EditInfo} alt="" /></div>
                    <div className="col-lg-10 col-9 bor-l  align-self-center">
                        <h4>We <span>couldn't</span> find <br className="d-block d-md-none" />
                            your agreements</h4>
                        <p>Don't worry, you can edit your details if they are incorrect, or add the lender if you know who provided your finance agreement.</p>
                    </div>
                </div>
                <div className="drop-in">
                    <div className="d-lg-flex flex-lg-nowrap gap-3 editcol-details">
                        <div className="col-lg-6 col-12">
                            <p><strong> Full Name</strong></p>
                            <>{fullName}</>
                        </div>
                        <div className="col-lg-6 col-12 mt-3 mt-lg-0">
                            <p><strong> Date of birth</strong></p>
                            <>{dob}</>
                        </div>
                    </div>
                    <div className="d-lg-flex flex-lg-nowrap gap-3 editcol-details">
                        <div className="col-lg-6 col-12">
                            <p><strong> Email</strong></p>
                            <>{prevFormData?.txtEmail}</>
                        </div>
                        <div className="col-lg-6 col-12 mt-3 mt-lg-0">
                            <p><strong> Phone Number</strong></p>
                            <>{prevFormData?.txtPhone}</>
                        </div>
                    </div>
                    <div className="d-flex flex-nowrap gap-3 editcol-details">
                        <div className="col-lg-12 col-12">
                            <p><strong> Current Address</strong></p>
                            {prevFormData?.txtHouseNumber && prevFormData?.txtHouseNumber + ", "}
                            {prevFormData?.txtAddress2 && prevFormData?.txtAddress2 + ", "}
                            {prevFormData?.txtCounty && prevFormData?.txtCounty + ", "}
                            {prevFormData?.txtTown && prevFormData?.txtTown + ", "}
                            {prevFormData?.txtDistrict && prevFormData?.txtDistrict + ", "}
                            {prevFormData?.txtCountry && prevFormData?.txtCountry}
                        </div>
                    </div>
                    <div className="mob-sticky">
                        <div className="d-flex flex-nowrap editcol-details justify-content-center border-0 m-0 pb-0">
                            <p className="m-0"><strong> Please confirm your details are correct</strong></p>
                        </div>
                        <div className="flex-div col-12 d-flex flex-nowrap flex-column flex-lg-row gap-0 gap-lg-4">
                            <button
                                type="button"
                                className="btn-back mt-lg-4 mt-0 order-2"
                                name="skip"
                                onClick={nextSlide}>
                                Skip &gt;
                            </button>
                            <button
                                type="button"
                                className="btn-banner next-slide2 mt-lg-4 mt-2 order-1"
                                name="edit"
                                onClick={nextSlide}
                            >
                                Edit Details
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserCurrentDetails