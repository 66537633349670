import React, { useState, useContext, useRef, useEffect } from "react";
import "../../assets/EditDetails/scss/main.scss";
import PersonalDetails from "../Includes/Layouts/EditDetails/PersonalDetails";
import Header from "../Includes/Layouts/EditDetails/Header";
import UserCurrentDetails from "../Includes/Layouts/EditDetails/UserCurrentDetails";
import Footer from "../Includes/Layouts/EditDetails/Footer";
import { FormProvider, useForm } from "react-hook-form";
import AddPreviousDetails from "../Includes/Layouts/EditDetails/AddPreviousDetails";
import { useSkippedLeads } from "../../Hooks/useSkippedLeads";
import { useHistory } from "react-router-dom";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";
import QueryStringContext from "../../Contexts/QueryString";
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
import { useGetCreditAnalysisData } from "../../Hooks/useGetCreditAnalysisData";
import { usePageLoad } from "../../Hooks/usePageLoad";
import { getPageName } from "../../Constants/PageNames";

const EditDetails = () => {
  const formMethods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const {
    register,
    errors,
    trigger,
    setError,
    clearErrors,
    getValues,
    watch,
    handleSubmit,
    setValue,
    unregister,
  } = formMethods;

  const [firstQuestion, setFirstQuestion] = useState("show");
  const [editPersonalDetails, setEditPersonalDetails] = useState("hide");
  const [haveotherAddress, setHaveotherAddress] = useState("hide");
  const [showFirstAddress, setShowFirstAddress] = useState("hide");
  const [showSecondAddress, setShowSecondAddress] = useState("hide");
  const [showThirdAddress, setShowThirdAddress] = useState("hide");
  const [f_name, setF_name] = useState("");
  const { skippedLeads } = useSkippedLeads();
  const prevFormData = JSON.parse(localStorage.getItem("formData"));
  const pCode = prevFormData.txtPostCode.toUpperCase();
  const splitName = localStorage.getItem("split_name");
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { formDataIngestion } = useDataIngestion();
  const { updateMilestone } = useUpdateMilestone();
  const { queryString } = useContext(QueryStringContext);
  const splitForm = useRef(null);
  const values = getValues();
  const formDatas = values;
  const { isLoading, SaveQuestionnaire } = useQuestionnaire();
  let prev_address = [];
  const { prevAddress, setPrevAddress } = useState([]);
  const { getCreditAnalysisData } = useGetCreditAnalysisData();
  const { savePageLoad, calledOnce } = usePageLoad();

  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters;

    if (visitorData != "") {
      var uuid = visitorData.uuid;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  const uuid = getUuid();

  useEffect(() => {
    if (!!uuid && !calledOnce) {
      const data = {
        uuid,
        page_name: getPageName("edit"),
        status: "visited",
        source: "regular",
      };
      (async () => {
        await savePageLoad(
          visitorParameters.visitor_parameters,
          data,
          "visited_status",
          splitName,
          queryString,
          visitorParameters.data
        );
      })();
    }
  }, [uuid, calledOnce]);

  const SkippedLeads = async (type, flag = true) => {
    const skippedLeadsResponse = await skippedLeads(type, uuid, splitName);

    if (flag && skippedLeadsResponse.data.status == "Success") {
      history.push("/questionaireUFC_DC_V1?uuid=" + uuid);
    }
  };
  const onSelectAnswer = async (e, param) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    let flage = false;
    let question_data = {};

    if (selectedAns && selectedQuest) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: null,
        input_answer: anslabel,
      };
    }
    console.log(question_data);

    if (flage) {
      storeQuestionnire(question_data);
      let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (_existingEntries == null) _existingEntries = [];
      let existingIndex = _existingEntries.findIndex(
        (entry) => entry.question_id === question_data.question_id
      );
      if (existingIndex !== -1) {
        _existingEntries.splice(existingIndex, 1);
      }
      _existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(_existingEntries));
    }
  };

  const storeQuestionnire = async (question_data) => {
    const response = await SaveQuestionnaire(
      visitorParameters.visitor_parameters,
      question_data,
      "question_store",
      visitorParameters.data,
      queryString,
      formDatas
    );
  };

  const PrevAddressSubmit = async () => {
    const response = await formDataIngestion(
      visitorParameters.visitor_parameters,
      prev_address,
      "prev_address_store",
      "UFC_DC_V1",
      visitorParameters.data
    );
    if (response.data.status == "Success") {
      const data = {
        uuid,
        page_name: getPageName("prevaddress"),
        status: "completed",
        source: "regular",
      };
      (async () => {
        await savePageLoad(
          visitorParameters.visitor_parameters,
          data,
          "visited_status",
          splitName,
          queryString,
          visitorParameters.data
        );
      })();
      CreditCheckCall();
    }
  };

  const CreditCheckCall = async () => {
    history.push("/credit-Analyze?uuid=" + uuid);
  };

  const formSubmit = async () => {
    localStorage.setItem("EditPage", "visited");
    const formDatas = getValues();

    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      localStorage.setItem("fName", formDatas.txtFName);
      localStorage.setItem("lName", formDatas.txtLName);
      localStorage.setItem("pCode", formDatas.txtPostCode);
      localStorage.setItem("addressLine1", formDatas.txtHouseNumber);
      localStorage.setItem("dobYr", formDatas.DobYear);
      localStorage.setItem("dobMon", formDatas.DobMonth);
      localStorage.setItem("dobDy", formDatas.DobDay);
      localStorage.setItem("addressLine2", formDatas.txtAddress2);
      localStorage.setItem("postTown", formDatas.txtTown);
      localStorage.setItem("country", formDatas.txtCountry);
      localStorage.setItem("title", formDatas.lstSalutation);
      localStorage.setItem("addressLine3", formDatas.txtAddress3);
      localStorage.setItem("county", formDatas.txtCounty);
      localStorage.setItem(
        "district",
        formDatas.txtDistrict ? formDatas.txtDistrict : ""
      );
      prevFormData.txtFName =  formDatas.txtFName;
      prevFormData.txtLName =  formDatas.txtLName;
      prevFormData.txtPostCode = formDatas.txtPostCode;
      prevFormData.txtHouseNumber = formDatas.txtHouseNumber;
      prevFormData.DobYear = formDatas.DobYear;
      prevFormData.DobMonth = formDatas.DobMonth;
      prevFormData.DobDay =  formDatas.DobDay;
      prevFormData.txtAddress2 = formDatas.txtAddress2;
      prevFormData.txtTown = formDatas.txtTown;
      prevFormData.txtCountry = formDatas.txtCountry;
      prevFormData.lstSalutation = formDatas.lstSalutation;
      prevFormData.txtAddress3 = formDatas.txtAddress3;
      prevFormData.txtCounty =  formDatas.txtCounty;
      prevFormData.txtEmail = formDatas.txtEmail;
      prevFormData.txtPhone = formDatas.txtPhone;
      prevFormData.txtDistrict = formDatas.txtDistrict ? formDatas.txtDistrict : "" ;
      prevFormData.fName = formDatas.txtFName;
      prevFormData.lName = formDatas.txtLName;
      prevFormData.pCode = formDatas.txtPostCode;
      prevFormData.addressLine1 = formDatas.txtHouseNumber;
      prevFormData.dobYr = formDatas.DobYear;
      prevFormData.dobMon = formDatas.DobMonth;
      prevFormData.dobDy = formDatas.DobDay;
      prevFormData.addressLine2 = formDatas.txtAddress2;
      prevFormData.postTown = formDatas.txtTown;
      prevFormData.country = formDatas.txtCountry;
      prevFormData.title = formDatas.lstSalutation;
      prevFormData.addressLine3 = formDatas.txtAddress3;
      prevFormData.county = formDatas.txtCounty;
      prevFormData.txtEmail = formDatas.txtEmail;
      prevFormData.txtPhone = formDatas.txtPhone;
      prevFormData.district = formDatas.txtDistrict
        ? formDatas.txtDistrict
        : "";

      let UcFname =
        formDatas.txtFName.charAt(0).toUpperCase() +
        formDatas.txtFName.slice(1);

      localStorage.setItem("UcFname", UcFname);
      localStorage.setItem("formData", JSON.stringify(prevFormData));
      localStorage.setItem("queryString", queryString);

      const formSUbmitResult = await formDataIngestion(
        visitorParameters.visitor_parameters,
        formDatas,
        "basic_form_update",
        "UFC_DC_V1",
        queryString,
        visitorParameters.data
      );
      if (formSUbmitResult.data.status === "Success") {
        const data = {
          uuid,
          page_name: getPageName("edit"),
          status: "completed",
          source: "regular",
        };
        (async () => {
          await savePageLoad(
            visitorParameters.visitor_parameters,
            data,
            "visited_status",
            splitName,
            queryString,
            visitorParameters.data
          );
        })();
        updateMilestone(
          visitorParameters.visitor_parameters.uuid,
          "lead",
          "live"
        );
        const prevdata = {
          uuid,
          page_name: getPageName("prevaddress"),
          status: "visited",
          source: "regular",
        };
        (async () => {
          await savePageLoad(
            visitorParameters.visitor_parameters,
            prevdata,
            "visited_status",
            splitName,
            queryString,
            visitorParameters.data
          );
        })();
      }
    } else {
      return "Fail";
    }
  };

  return (
    <div className="edit-details">
      <GetVisitorsParams />
      <FormProvider {...formMethods}>
        <div>
          <Header />
          <section className="editpage-banner">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12  align-self-center order-lg-1 order-2 offset-lg-3  ">
                  <form
                    ref={splitForm}
                    name="split_form"
                    id="user_form"
                    method="POST"
                    autoComplete="off"
                  >
                    <UserCurrentDetails
                      firstQuestion={firstQuestion}
                      prevFormData={prevFormData}
                      setFirstQuestion={setFirstQuestion}
                      setEditPersonalDetails={setEditPersonalDetails}
                      SkippedLeads={SkippedLeads}
                      uuid={uuid}
                    />

                    <PersonalDetails
                      editPersonalDetails={editPersonalDetails}
                      register={register}
                      clearErrors={clearErrors}
                      errors={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      setF_name={setF_name}
                      prevFormData={prevFormData}
                      setEditPersonalDetails={setEditPersonalDetails}
                      setHaveotherAddress={setHaveotherAddress}
                      setValue={setValue}
                      unregister={unregister}
                      watch={watch}
                      visitorParameters={visitorParameters}
                      formSubmit={formSubmit}
                    />

                    <AddPreviousDetails
                      haveotherAddress={haveotherAddress}
                      pCode={pCode}
                      showFirstAddress={showFirstAddress}
                      setShowFirstAddress={setShowFirstAddress}
                      showSecondAddress={showSecondAddress}
                      setShowSecondAddress={setShowSecondAddress}
                      showThirdAddress={showThirdAddress}
                      setShowThirdAddress={setShowThirdAddress}
                      getValues={getValues}
                      trigger={trigger}
                      errors={errors}
                      uuid={uuid}
                      onSelectAnswer={onSelectAnswer}
                      setPrevAddress={setPrevAddress}
                      PrevAddressSubmit={PrevAddressSubmit}
                      prev_address={prev_address}
                      CreditCheckCall={CreditCheckCall}
                    />
                  </form>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </FormProvider>
    </div>
  );
};

export default EditDetails;
