import React from 'react'

const RecieveAnyIncome = ({
    setRecieveIncomeback,
    onSelectAnswer,
    backClick }) => {
    const optionHandle = async (e) => {

        if (e.target.value === "267") {
            setRecieveIncomeback("267");
            onSelectAnswer(e);
        } else if (e.target.value === "268") {
            setRecieveIncomeback("268");
            onSelectAnswer(e);
        }

    }
    
    return (
        <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
            <div className="row">
                <div className="col-6">
                    <div className=" mb-2">
                        <button
                            type="button"
                            className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn`}
                            onClick={optionHandle}
                            value="267"
                            name="recieveanyincome"
                            id="nextscroll"
                            data-quest-id={100}
                            data-ans-id={267}
                            data-ans-label={null}
                        >Yes
                        </button>
                    </div>
                </div>
                <div className="col-6">
                    <div className=" mb-2">
                        <button
                            type="button"
                            className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 noissue`}
                            onClick={optionHandle}
                           value="267"
                            name="recieveanyincome"
                            id="nextscroll"
                            data-quest-id={100}
                            data-ans-id={268}
                            data-ans-label={null}
                        >No
                        </button>
                    </div>
                </div>
            </div>
            <p className="text-center bck-marwid">
                <button
                    name="financialhardship"
                    className="btn btn-secondary backbtn1a"
                    id=""
                    value=""
                    type="button"
                    onClick={backClick}
                >
                    Go Back
                </button>
            </p>
        </div>
    )
}

export default RecieveAnyIncome;