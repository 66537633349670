import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
import RadioButtonQuest from '../../../UI/RadioButtonQuest';
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const OccasionalUnforeseenEmergency = ({ clsName,onSelectAnswer, unQualifiedAnswer }) => {


    const [claimModal, setshowClaimModal] = useState(false);
    const handleCloseclaimModal = () => setshowClaimModal(false);
    const optionHandle = async (e) => {
        console.log(e.target.value);

        GtmDataLayer({
            question: "overdraft usage",
        });
        if (e.target.value === "299") {
            unQualifiedAnswer(e);
            setshowClaimModal(true);
        } else if (e.target.value === "298") {
            onSelectAnswer(e);
        }

    }

    return (
        <>
            <div className={`qsSlider slidea1 ${clsName}`}>
                <h2 className="qs-title slide-up-now">
                    Did you use your overdraft for the occasional unforeseen
                    emergency or was it used regularly for day-to-day expenditure?
                </h2>
                <div className="btn-option optinal-items  qs-legthy slide-up-now">
                    <div className=" option_but mb-3">
                        <RadioButtonQuest
                            key="occ1"
                            className="radio"
                            labelName=" I often used my overdraft for a prolonged period and for day-to -day expenditure (e.g. if you
                            were often living in your overdraft)"
                            labelClassName="align-items-center btn-next"
                            onClick={optionHandle}
                            value="298"
                            name="OccasionalUnforeseenEmergency"
                            id="occ1"
                            dataQuestId={108}
                            dataAnsId={298}
                            dataAnsLabel=" I often used my overdraft for a prolonged period and for day-to -day expenditure (e.g. if you
                            were often living in your overdraft)"
                        />

                    </div>
                    <div className=" option_but ">
                        <RadioButtonQuest
                            key="occ2"
                            className="radio"
                            labelName="I used my overdraft only for the odd emergency then I was back in credit"
                            labelClassName="align-items-center btn-next"
                            onClick={optionHandle}
                            value="299"
                            name="OccasionalUnforeseenEmergency"
                            id="occ2"
                            dataQuestId={108}
                            dataAnsId={299}
                            dataAnsLabel="I used my overdraft only for the odd emergency then I was back in credit"
                        />
                    </div>
                    {/* <div className="mt-2 mb-5 text-center">
                        <div>
                            {" "}
                            <button className="back-btn mainbckBtn" id="backqs2">
                                <i className="bi bi-chevron-left" /> Back
                            </button>
                        </div>
                    </div> */}
                </div>
            </div>



            <Modal
                show={claimModal}
                onHide={handleCloseclaimModal}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="creditpopup">
                    <div className="pb-3">
                        <div className="row align-items-center">
                            <div className="col-lg-12 col-md-12 col-sm-3 col-2 text-center">
                                <div className="mb-lg-3 mb-md-3 mb-sm-0 mb-0">
                                    <img
                                        className="alertimg"
                                        src="../../assets/QuestionaireUFC_DC_V1/img/alert.png"
                                        height="50"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-9 col-10">
                                <h6 className="mb-0 text-lg-center text-md-center text-sm-start text-start px-lg-3 px-md-3 px-sm-0 px-0">
                                    Sorry we are not able to assist
                                </h6>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={closeModal}>
            Close
          </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default OccasionalUnforeseenEmergency;
