import React, { useEffect, useState } from "react";
import "../../../assets/UFC_DC_V1/scss/modules/custom.scss";
import "../../../assets/UFC_DC_V1/scss/modules/anim.scss";
import "../../../assets/UFC_DC_V1/scss/modules/checklist.scss";
import "../../../assets/UFC_DC_V1/scss/modules/personaldetails.scss";
import "../../../assets/UFC_DC_V1/scss/modules/questionnaire.scss";
import Footer from '../../Includes/Layouts/UFC_DC_V1/Footer';
import FormUFC_DC_V1 from "../../Forms/FormUFC_DC_V1";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FbViewPixel from "../../../Utility/FbViewPixel";



const UFC_DC_V1 = () => {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="UFC_DC_V1" />
      <FbViewPixel />
      <div className='UFC_DC_V1'>
        <FormUFC_DC_V1 />
        <Footer />
      </div>
    </>
  )
}

export default UFC_DC_V1;